<template>
  <v-card class="default--dialog__card">
    <v-card-title>
      <span>
        {{ texts.title }}
      </span>
    </v-card-title>
    <v-card-text>
      <!-- Contact form -->
      <div
        v-if="!displayCalendarLink"
        key="contactForm"
      >
        <p>
          {{ texts.explain }}
        </p>
        <p>
          {{ texts.soloLimiting }}
        </p>
        <div>
          {{ texts.contactUsFor }}
        </div>
        <ul>
          <li>
            {{ texts.enterpriseExplain }}
          </li>
          <li>
            {{ texts.freeExplain }}
          </li>
        </ul>
        <div class="mt-4">
          {{ texts.preferences }}
        </div>
        <v-textarea
          v-model="message"
          outlined
          :label="labels.textArea"
          class="mt-2"
        />
        <p>
          <span>
            {{ texts.subscriptionLink }}
          </span>
          <a
            target="_blank"
            href='https://clearance.aero/tarifs/'
          >
            https://clearance.aero/tarifs
          </a>.
        </p>
      </div>
      <!-- Calendar form -->
      <div
        v-else
        key="calendarForm"
      >
        <p>
          {{ texts.messageSent }}
        </p>
        <p>
          <span>
            {{ texts.appointement }}
          </span>
          <a
            target="_blank"
            href="https://meetings.hubspot.com/florent-mainfroy"
          >
            https://meetings.hubspot.com/florent-mainfroy
          </a>.
        </p>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="info"
        @click="close()"
      >
        <span
          v-if="!displayCalendarLink"
          key="cancelButton"
        >
          {{ texts.cancel }}
        </span>
        <span
          v-else
          key="quitButton"
        >
          {{ texts.quit }}
        </span>
      </v-btn>
      <v-btn
        v-if="!displayCalendarLink"
        color="primary"
        text
        @click="sendWarnManagersUpgradeEnterprise()"
        :loading="loadingSubscriptionUpdate"
      >
        {{ texts.contactUs }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { WARN_MANAGERS_UPGRADE_TO_ENTERPRISE_NS } from '@/store/exploitants';

export default {
  name: 'SubscriptionPlanEnterprise',
  data() {
    return {
      message: '',
      displayCalendarLink: false,
    };
  },
  computed: {
    loadingSubscriptionUpdate() {
      return this.$store.state.exploitants.subscriptionWarnUpgradeLoading;
    },
    labels() {
      return { textArea: this.$gettext('This message will be sent by mail to our team') };
    },
    texts() {
      return {
        title: this.$gettext('Update formula'),
        explain: this.$gettext(`You account is currently in Solo formula, either because this is
          your current subscription, or because you are benefiting from a trial period following
          your registration.`),
        soloLimiting: this.$gettext('The Solo formula is limited to a single pilot.'),
        contactUsFor: this.$gettext('Contact us for:'),
        enterpriseExplain: this.$gettext(`Find out more about Enterprise formula, benefit from all
          the features of the platform with several pilots, or;`),
        freeExplain: this.$gettext(`Switch to Free formula, with limited features but the
          possibility of having several pilot profiles.`),
        preferences: this.$gettext('Please indicate your preference or requirements below:'),
        subscriptionLink: this.$gettext('To find out more about our subscription offers:'),
        messageSent: this.$gettext(`Your message has been received and we will get back to you as
          soon as possible.`),
        appointement: this.$gettext('Choose the time that suits you best to talk:'),
        cancel: this.$gettext('Cancel'),
        quit: this.$gettext('Quit'),
        contactUs: this.$gettext('Contact us'),
      };
    },
  },
  methods: {
    sendWarnManagersUpgradeEnterprise() {
      this.$store.dispatch(WARN_MANAGERS_UPGRADE_TO_ENTERPRISE_NS, { message: this.message })
        .then(() => {
          this.displayCalendarLink = true;
        });
    },
    close() {
      this.$emit('close');
      this.message = '';
      this.displayCalendarLink = false;
    },
  },
};
</script>
