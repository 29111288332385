/* eslint-disable max-classes-per-file */
import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { RulerControl } from 'mapbox-gl-controls';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
/* eslint-disable max-classes-per-file */

import { MAPBOX_TOKEN, MAPBOX_USERNAME, MAPBOX_DEFAULT_STYLE_ID } from '@/settings';

/* eslint-disable import/no-cycle */
import { APPLICATION_TAB } from '@/store/application';
import { FLIGHT_EXCLUSION_LAYER_IDENTIFIERS } from '@/store/map';
/* eslint-disable import/no-cycle */

import getCustomGeocoder from '@/components/Map/MapboxGeocoder';
import MapAreasHistoryControl from '@/components/Map/MapAreasHistoryControl';
import MapLayersControl from '@/components/Map/MapLayersControl';
import MapboxDrawStyle from '@/components/Map/MapboxDrawStyle';
import {
  LAYER_STYLES,
  DEFAULT_POLYGON_STYLE,
  DEFAULT_RASTER_STYLE,
  POPUPS_STYLES,
} from '@/components/Map/MapLayerStyles';

export const MODE = {
  WRITE: 'WRITE',
  READ: 'READ',
};

// In Mapbox map object, we prefix any layer and source name
// to be able to clearly identify the objects we created.
const LAYER_SOURCE_PREFIX = 'CLEARANCE_';

const emptySourceData = { type: 'FeatureCollection', features: [] };

const CONVERSION_FROM_KM = { NM: 1.852 };

mapboxgl.accessToken = MAPBOX_TOKEN;

export function featureCollectionToMultiPolygon(featureCollection) {
  if (featureCollection) {
    const multiPolygon = {
      type: 'MultiPolygon',
      coordinates: [],
    };
    featureCollection.features.forEach((feature) => {
      if (feature.geometry.type === 'MultiPolygon') {
        feature.geometry.coordinates.forEach((polygon) => {
          multiPolygon.coordinates.push(polygon);
        });
      } else if (feature.geometry.type === 'Polygon') {
        multiPolygon.coordinates.push(feature.geometry.coordinates);
      }
    });
    return multiPolygon;
  }
  return null;
}

// The identifier of the outline layer for a custom layer using the custom layer identifier
export function formatCustomLayerOutlineIdentifier(customLayerIdentifier) {
  return `${customLayerIdentifier}-OUTLINE`;
}

// The identifier of the Labels layer for a custom layer using the custom layer identifier
export function formatCustomLayerLabelsIdentifier(customLayerIdentifier) {
  return `${customLayerIdentifier}-LABELS`;
}

// Use only -label for plan map and need poi- and place- for satellite plan
const LABEL_TO_TRANSLATE_REGEX = /(-label)|(poi-)|(place-)/;

function setFrenchLanguageLabelName(map) {
  map.onLoad(() => {
    map.getStyle().layers.filter(
      (layer) => layer.type === 'symbol' && LABEL_TO_TRANSLATE_REGEX.exec(layer.id),
    ).forEach((layer) => {
      map.setLayoutProperty(layer.id, 'text-field', [
        'coalesce',
        [
          'get',
          'name_fr',
        ],
        [
          'get',
          'name_en',
        ],
        [
          'get',
          'name',
        ],
      ]);
    });
  });
}

function loadMobileSiteIcon(map) {
  if (map) {
    map.loadImage(
      `${process.env.BASE_URL}antenna-custom.png`,
      (error, image) => {
        if (error) throw error;
        if (map && !map.hasImage('mobile_site')) {
          map.addImage('mobile_site', image);
        }
      },
    );
  }
}

function loadDroneIcon(map) {
  if (map) {
    map.loadImage(
      `${process.env.BASE_URL}drone_icon.png`,
      (error, image) => {
        if (error) throw error;
        if (map && !map.hasImage('drone')) {
          map.addImage('drone', image);
        }
      },
    );
    map.loadImage(
      `${process.env.BASE_URL}drone_icon_livestream.png`,
      (error, image) => {
        if (error) throw error;
        if (map && !map.hasImage('drone_livestream')) {
          map.addImage('drone_livestream', image);
        }
      },
    );
    map.loadImage(
      `${process.env.BASE_URL}arrow.png`,
      (error, image) => {
        if (error) throw error;
        if (map && !map.hasImage('speed-vector')) {
          map.addImage('speed-vector', image);
        }
      },
    );
    map.loadImage(
      `${process.env.BASE_URL}plane_green.png`,
      (error, image) => {
        if (error) throw error;
        if (map && !map.hasImage('green_plane')) {
          map.addImage('green_plane', image);
        }
      },
    );
    map.loadImage(
      `${process.env.BASE_URL}plane_orange.png`,
      (error, image) => {
        if (error) throw error;
        if (map && !map.hasImage('orange_plane')) {
          map.addImage('orange_plane', image);
        }
      },
    );
    map.loadImage(
      `${process.env.BASE_URL}plane_red.png`,
      (error, image) => {
        if (error) throw error;
        if (map && !map.hasImage('red_plane')) {
          map.addImage('red_plane', image);
        }
      },
    );
  }
}

function simplifiedFlightFormDrawModeClickEvent(state, e) {
  const { parent } = this.map;
  parent._actionOnClickableLayersInWriteMode(parent._clickableLayerNames, e);
  e.preventDefault();
}

function retrievePylonsIdentifierFromFeature(feature) {
  return (
    feature.properties['data-type'] === 'line'
      ? feature.properties['data-numero_portee'].split('-').map(
        (id) => parseInt(id, 10),
      )
      : [parseInt(feature.properties['data-numero_pylone'], 10)]
  ).filter((id) => !Number.isNaN(id));
}

class CustomRulerControl extends RulerControl {
  constructor(unitsDisplayed) {
    super();
    this.labelFormat = (n) => {
      let distanceText = super().labelFormat(n);
      unitsDisplayed().forEach((unit) => {
        const unitConvert = CONVERSION_FROM_KM[unit];
        if (unitConvert) {
          const newDistance = n / unitConvert;
          distanceText += ` (${newDistance.toFixed(2)} ${unit})`;
        }
      });
      return distanceText;
    };
  }
}

class BaseLayer {
  constructor({
    identifier,
    mainURL,
    accessGranted,
    name,
    type,
    fallbackURL,
    displayPriority,
    specificMapClic,
    parentComponent,
  }) {
    this.identifier = identifier;
    this.name = name;
    this.type = type;
    this.fallbackURL = fallbackURL;
    this.mainURL = mainURL;
    this.displayPriority = displayPriority;
    this.specificMapClic = specificMapClic;
    this.parent = parentComponent;
    this.accessGranted = accessGranted;
  }

  makeMapboxStyle() {
    if (this.type === 'MapboxMap') {
      return this.mainURL;
    }
    return this.fallbackURL;
  }

  deactivate(map) {
    const that = this;
    if (that.type === 'rasterWMS') {
      if (map.getLayer(that.identifier)) {
        map.removeLayer(that.identifier);
      }
      if (map.getSource(that.identifier)) {
        map.removeSource(that.identifier);
      }
    }
  }

  activate({ map, redrawBaseStyle }) {
    const that = this;
    /* eslint no-param-reassign: ["error", { "props": false }] */
    map.isActuallyLoaded = false;
    const baseURL = this.type === 'MapboxMap' ? this.mainURL : this.fallbackURL;
    if (redrawBaseStyle !== false) {
      map.setStyle(baseURL, { diff: false });
    }
    if (this.type === 'MapboxMap') {
      that.parent._addLayers();
    } else if (this.type === 'rasterWMS') {
      map.onLoad(() => {
        const baseLayerStyle = LAYER_STYLES[that.identifier];
        const rasterSource = {
          type: 'raster',
          tiles: [that.mainURL],
          tileSize: 256,
        };
        Object.keys(baseLayerStyle.sourceOptions).forEach((key) => {
          rasterSource[key] = baseLayerStyle.sourceOptions[key];
        });
        map.addSource(that.identifier, rasterSource);
        const baseLayer = {
          id: that.identifier,
          source: that.identifier,
          type: 'raster',
        };
        Object.keys(baseLayerStyle.mapboxStyleOptions).forEach((key) => {
          baseLayer[key] = baseLayerStyle.mapboxStyleOptions[key];
        });
        if (map.getLayer('gl-draw-polygon-fill-inactive.cold')) {
          map.addLayer(baseLayer, 'gl-draw-polygon-fill-inactive.cold');
        } else {
          map.addLayer(baseLayer);
        }
        that.parent._addLayers();
      });
    }
    setFrenchLanguageLabelName(map);
    that.parent.activeBaseLayer = that;
    that.parent._setClickableLayersEvents(that.parent._clickableLayerNames);
  }
}

class DataLayer {
  constructor({
    identifier,
    name,
    url,
    thumbnailUrl,
    type,
    layerType,
    sourceName,
    style,
    displayPriority,
    persistent,
    visible,
    styleIdentifier,
    status,
    opacity,
    category,
  }) {
    this.identifier = identifier;
    this.name = name;
    this.url = url;
    this.thumbnailUrl = thumbnailUrl;
    this.type = type;
    this.layerType = layerType;
    this.sourceName = sourceName;
    this.style = style;
    this.displayPriority = displayPriority;
    this.mapboxLayerId = LAYER_SOURCE_PREFIX + identifier;
    this.persistent = persistent;
    this.visible = visible;
    this.styleIdentifier = styleIdentifier;
    this.status = status;
    this.opacity = opacity;
    this.category = category;
  }

  hide(map) {
    if (!this.persistent) {
      map.setLayoutProperty(this.mapboxLayerId, 'visibility', 'none');
    }
  }

  show(map) {
    map.setLayoutProperty(this.mapboxLayerId, 'visibility', 'visible');
  }
}

class Map {
  constructor(
    idContainer,
    {
      sourcesData,
      isUserDronist,
      isSubscriptionActive,
      isUserEnterprise,
      isUserGeneralist,
      isMobileBreakpoint,
      unitsDisplayed,
      checkClientStructuresGeocodingAllowed,
      addPopupCallback,
      removePopupCallback,
      selectableLayerIds,
      updateFeatureHovered,
      updateFeatureSelected,
      updateConstraintsPoint,
      updateOnSpecificMapClic,
      updateAreaFromClientStructure,
      setMapDataLoaded,
      updateArea,
      availableMaps,
      availableMapsData,
      customLayers,
      clickableLayerNames,
      setBoundingBoxIsUpdating,
      updateForbiddenZone,
      updateMarkerCount,
      showMobileNetworkLayersImportDialog,
      showGeoportailLayersImportDialog,
      showLayerImportDialog,
      openEditLayers,
      openDeleteLayers,
      checkAreaCanBeDeleted,
    },
  ) {
    const that = this;
    this.sourcesData = sourcesData || {};
    this._isUserDronist = isUserDronist || false;
    this._isSubscriptionActive = isSubscriptionActive || false;
    this._isUserEnterprise = isUserEnterprise || false;
    this._isUserGeneralist = isUserGeneralist || false;
    this._isMobileBreakpoint = isMobileBreakpoint || false;
    this._unitsDisplayed = unitsDisplayed;
    this._checkClientStructuresGeocodingAllowed = checkClientStructuresGeocodingAllowed;
    this._addPopupCallback = addPopupCallback;
    this._removePopupCallback = removePopupCallback;
    this._selectableLayerIds = selectableLayerIds;
    this._updateIdHover = updateFeatureHovered;
    this._updateIdSelected = updateFeatureSelected;
    this._updateConstraintsPoint = updateConstraintsPoint;
    this._updateOnSpecificMapClic = updateOnSpecificMapClic;
    this._updateAreaFromClientStructure = updateAreaFromClientStructure;
    this._setMapDataLoaded = setMapDataLoaded;
    this._availableMapsData = availableMapsData;
    this._customLayers = customLayers;
    this._clickableLayerNames = clickableLayerNames;
    this._setBoundingBoxIsUpdating = setBoundingBoxIsUpdating;
    this._updateForbiddenZone = updateForbiddenZone;
    this._updateMarkerCount = updateMarkerCount;
    this._showMobileNetworkLayersImportDialog = showMobileNetworkLayersImportDialog;
    this._showGeoportailLayersImportDialog = showGeoportailLayersImportDialog;
    this._showLayerImportDialog = showLayerImportDialog;
    this._checkAreaCanBeDeleted = checkAreaCanBeDeleted;
    this._openEditLayers = (value) => {
      value.layers = this.layers.filter((l) => value.layerIds.includes(l.identifier));
      openEditLayers(value);
    };
    this._openDeleteLayers = openDeleteLayers;
    this.setLayerGroupVisibility = (layerIds, visibility) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const id of layerIds) {
        if (visibility === 'visible') {
          this.showLayer(id);
        } else {
          this.hideLayer(id);
        }
      }
    };
    this.layerIdsCategoryUnvisible = {
      data: [],
      tracking: [],
      custom: [],
      geoportail_wmts: [],
      custom_data: [],
      mobile_network: [],
    };
    this.setLayerCategoryVisibility = (category, layerIds) => {
      this.layerIdsCategoryUnvisible[category] = layerIds;
    };
    this._mode = MODE.READ;
    this.editExclusionZone = false;
    this.lastMarkerId = 0;
    this.clientStructuresClicked = undefined;
    this.areasHistory = [];

    // this function allow the MapboxGL to propagate
    // the area(s) drawn on the map via MapboxGLDraw plugin
    this._updateArea = (storeArea = true) => {
      if (this._drawControl) {
        const featureCollection = this._drawControl.getAll();

        if (storeArea) this.areasHistory.push(featureCollection);

        if (featureCollection.features.length) {
          updateArea(featureCollection);
          if (this.editExclusionZone) {
            this.removeMarkersOutsideFlightArea();
            this.drawBufferSizeExclusionZoneFirstPlan();
          }
        } else {
          updateArea(null);
        }
      }
    };
    this.isActuallyLoaded = false;

    // Used to store popups linked to a layer
    // key: layer id  ;  value: list of mapbox popups
    this._layerPopups = {};

    // Used to store makers added to map
    // key: marker id  ;  value: mapbox marker object
    this._mapMarkers = {};

    // Prepare basemap layers that can be activated by the user.
    // Sort by highest priority first.
    this._baselayers = [];
    this.layers = [];
    const maps = availableMaps.concat();
    maps.forEach((rawLayer) => {
      const layer = new BaseLayer({
        identifier: rawLayer.identifier,
        mainURL: rawLayer.main_url_access,
        accessGranted: rawLayer.access_granted,
        name: rawLayer.name,
        type: rawLayer.map_type,
        fallbackURL: rawLayer.fallback_url,
        displayPriority: rawLayer.dynamic_display_priority,
        specificMapClic: rawLayer.specific_map_clic,
        parentComponent: that,
      });
      this._baselayers.push(layer);
    });
    this._baselayers.sort((a, b) => b.displayPriority - a.displayPriority);
    // Load map with special authentication for IGN webtiles.
    const defaultLayer = this._baselayers.reduce(
      (prev, current) => (prev.displayPriority > current.displayPriority ? prev : current),
      {},
    );
    this._map = new mapboxgl.Map({
      container: idContainer,
      style: defaultLayer.makeMapboxStyle(),
      attributionControl: false,
      center: [2.35, 48.85],
      // PreservedDrawingBuffer must be set to true for map screenshots
      preserveDrawingBuffer: true,
      zoom: 6,
      transformRequest: (url) => {
        if (url.startsWith('https://wxs.ign.fr')) {
          return {
            url,
            headers: { Authorization: `Basic ${process.env.VUE_APP_GEOPORTAIL_BASIC_TOKEN}` },
          };
        }
        return { url };
      },
    })
      .addControl(getCustomGeocoder(this._checkClientStructuresGeocodingAllowed), 'top-right')
      .addControl(new mapboxgl.FullscreenControl(), 'top-right')
      .addControl(new mapboxgl.AttributionControl({ compact: true }))
      .addControl(new mapboxgl.NavigationControl(), 'top-right')
      .addControl(new CustomRulerControl(this._unitsDisplayed), 'top-right')
      .addControl(new mapboxgl.ScaleControl({ maxWidth: 350 }), 'bottom-left');
    if (this._isUserDronist && this._isMobileBreakpoint) {
      this._map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: { enableHighAccuracy: true },
          // Indicate in which direction the device is heading
          showUserHeading: true,
        }),
        'top-right',
      );
    }
    this._map.parent = this;
    this._map.customLayersLoaded = false;

    // helper function to be able to display
    this._map.onLoad = (cb) => {
      function cb2() {
        that._map.isActuallyLoaded = true;
        cb();
      }
      function cb3() {
        that._map.isActuallyLoaded = true;
        cb();
      }
      function cb4() {
        that._map.isActuallyLoaded = true;
        cb();
      }
      if (
        (that._map.customLayersLoaded && that._map.isActuallyLoaded)
        || (that._map.loaded() && that._map.isStyleLoaded())
      ) {
        cb2();
      } else if (!that._map.areTilesLoaded()) {
        that._map.once('data', cb4);
      } else if (!that._map.isStyleLoaded()) {
        that._map.once('data', cb3);
      } else {
        cb2();
      }
      return that._map;
    };

    this._setSelectableLayersEvents();

    const simplifiedFlightFormDrawMode = { ...MapboxDraw.modes.simple_select };
    simplifiedFlightFormDrawMode.onClick = simplifiedFlightFormDrawModeClickEvent;
    simplifiedFlightFormDrawMode.onTap = simplifiedFlightFormDrawModeClickEvent;

    const directSelectMode = MapboxDraw.modes.direct_select;
    const _onTrashDirectMode = directSelectMode.onTrash;
    // eslint-disable-next-line func-names
    directSelectMode.onTrash = function (state) {
      const feature = that._drawControl.getSelected().features?.at(0) || {};
      if (feature?.geometry?.coordinates?.at(0).length === 4) {
        that._checkAreaCanBeDeleted(feature)
          .then((canBeDeleted) => {
            if (canBeDeleted) {
              _onTrashDirectMode.apply(this, [state]);
            }
          });
      } else {
        _onTrashDirectMode.apply(this, [state]);
      }
    };

    const simpleSelectMode = MapboxDraw.modes.simple_select;
    // eslint-disable-next-line func-names
    simpleSelectMode.onTrash = function () {
      const feature = that._drawControl.getSelected().features?.at(0) || {};
      that._checkAreaCanBeDeleted(feature)
        .then((canBeDeleted) => {
          if (canBeDeleted) {
            that.deleteArea(feature.id);
          }
        });
    };

    this._drawControl = new MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,
      controls: {
        polygon: true,
        trash: true,
      },
      styles: MapboxDrawStyle,
      modes: {
        simplified_flight_form: simplifiedFlightFormDrawMode,
        simple_select: simpleSelectMode,
        direct_select: directSelectMode,
        ...MapboxDraw.modes,
      },
    });
    this._map.on('draw.create', this._updateArea);
    this._map.on('draw.delete', this._updateArea);
    this._map.on('draw.update', this._updateArea);
    this._map.on('load', () => {
      this._controlLayer = new MapLayersControl({
        baseLayers: this._baselayers,
        mapsData: this._availableMapsData,
        persistentLayerIds: that.findPersistenLayerIds(),
        isUserDronist: this._isUserDronist,
        isSubscriptionActive: this._isSubscriptionActive,
        isUserEnterprise: this._isUserEnterprise,
        isUserGeneralist: this._isUserGeneralist,
      });
      this._map.addControl(this._controlLayer);
      this._controlAreasHistory = new MapAreasHistoryControl();
      defaultLayer.activate({
        map: this._map,
        redrawBaseStyle: false,
      });
    });
    this._map.onLoad(() => {
      loadMobileSiteIcon(this._map);
      loadDroneIcon(this._map);
    });
    this._map.on('style.load', () => {
      loadMobileSiteIcon(this._map);
      loadDroneIcon(this._map);
    });
  }

  findPersistenLayerIds() {
    return this.layers.filter((layer) => layer.persistent === true).map((l) => l.identifier);
  }

  _addEmptySource(sourceId) {
    if (!this._map.getSource(sourceId)) {
      this._map.addSource(sourceId, {
        type: 'geojson',
        data: { ...emptySourceData },
      });
    }
  }

  _setSelectableLayersEvents() {
    this._selectableLayerIds.forEach((layerId) => {
      const layerIdWithPrefix = `${LAYER_SOURCE_PREFIX}${layerId}`;
      this._map.on('mouseenter', layerIdWithPrefix, (e) => {
        this._map.getCanvas().style.cursor = 'pointer';
        const feature = e.features[0];
        this._updateIdHover({ featureId: feature ? feature.id : null, layerId });
      });
      this._map.on('mouseleave', layerIdWithPrefix, () => {
        this._updateIdHover({ featureId: null, layerId });
        this._map.getCanvas().style.cursor = '';
      });
      this._map.on('click', layerIdWithPrefix, (e) => {
        e.originalEvent.consumed = true;
        const feature = e.features[0];
        this._updateIdSelected({ featureId: feature ? feature.id : null, layerId });
      });
    });
  }

  setMapPointConstraintsEvents() {
    this._map.on('ruler.on', () => {
      this._isRulerActivated = true;
      this._updateConstraintsPoint(null);
      this._updateOnSpecificMapClic(null, null);
    });
    this._map.on('ruler.off', () => {
      this._isRulerActivated = false;
    });
    this._map.on('click', (e) => {
      this._mapDoubleClick = false;
      setTimeout(() => {
        if (!this._mapDoubleClick && !this._isRulerActivated) {
          if (this._mode !== MODE.WRITE && !e.originalEvent.consumed) {
            if (!this.activeBaseLayer.specificMapClic) {
              this._updateConstraintsPoint(e.lngLat);
            }
          }
        }
      }, 250);
    });
    this._map.on('dblclick', () => {
      this._mapDoubleClick = true;
    });
  }

  _setClickableLayersEvents(layerNames) {
    if (!(layerNames)) { return; }
    this._map.onLoad(() => {
      if (this.activeBaseLayer.specificMapClic) {
        this._addClickableLayersEvents(layerNames);
        this._updateConstraintsPoint(null);
      } else {
        this._removeClickableLayersEvents(layerNames);
        this._updateOnSpecificMapClic(null, null);
      }
    });
  }

  _addClickableLayersEvents(layerNames) {
    layerNames.forEach((layerName) => {
      this._setLayerCursorPointerOnHover(layerName);
    });
    this._clickableLayersListener = this._actionOnClickableLayers.bind(this, layerNames);
    this._map.on('click', this._clickableLayersListener);
  }

  _removeClickableLayersEvents(layerNames) {
    layerNames.forEach((layerName) => {
      this._removeLayerCursorPointerOnHover(layerName);
    });
    this._map.off('click', this._clickableLayersListener);
  }

  _pointerOnMouseEnter() {
    this.getCanvas().style.cursor = 'pointer';
  }

  _pointerOffMouseLeave() {
    this.getCanvas().style.cursor = '';
  }

  getClientStructureAtClick(layerNames, e) {
    // get feature in a bbox of 8px around click point
    const features = this._map.queryRenderedFeatures(
      [[e.point.x - 8, e.point.y - 8], [e.point.x + 8, e.point.y + 8]],
      { layers: layerNames },
    );
    if (features.length) {
      let popPoint;
      const clicPoint = turf.point([e.lngLat.lng, e.lngLat.lat]);
      const feature = features[0];
      if (
        feature.geometry.type === 'LineString'
      ) {
        // place popup on the nearest point in the line to the clic point
        popPoint = turf.nearestPointOnLine(feature, turf.point([e.lngLat.lng, e.lngLat.lat]));
      } else if (
        feature.geometry.type === 'Polygon' && turf.booleanWithin(clicPoint, feature)
      ) {
        // place popup on the clic point if it's within the polygon
        popPoint = clicPoint;
      } else {
        // place popup on a point on the feature (feature is a point or clic was near polygon)
        popPoint = turf.pointOnFeature(feature);
      }
      return [feature, popPoint];
    }
    return [null, null];
  }

  _actionOnClickableLayersInReadMode(layerNames, e) {
    const [feature, popPoint] = this.getClientStructureAtClick(layerNames, e);
    if (feature) {
      if (feature.properties['data-type'] !== 'site') {
        this.clientStructuresClicked = {
          data: [
            {
              litIdrs: feature.properties['data-lit_idr'].split(';'),
              pylonsIdentifier: retrievePylonsIdentifierFromFeature(feature),
            },
          ],
          geometryBuffered: turf.buffer(feature.geometry, 50, { units: 'meters' }),
        };
      } else {
        this.clientStructuresClicked = undefined;
      }
      this._updateOnSpecificMapClic(feature.id, popPoint.geometry.coordinates);
    } else {
      this.clientStructuresClicked = undefined;
      this._updateOnSpecificMapClic(null, null);
    }
  }

  retrieveElementsFromClickableLayers(layerNames, popPoint = null) {
    let mapboxBbox;
    if (popPoint) {
      // Create a geometry collection of the current clic and the current geometry
      let geometryEnvelope = turf.envelope(this.clientStructuresClicked.geometryBuffered);
      const geometryEnvelopeReduced = turf.buffer(geometryEnvelope, -100, { units: 'meters' });
      geometryEnvelope = geometryEnvelopeReduced || geometryEnvelope;
      const geometryCollection = turf.featureCollection([geometryEnvelope, popPoint]);
      const bbox = turf.bbox(geometryCollection);
      // Project the geographical coordinates in pixel coordinates
      const bottomLeftPointOnScreen = this._map.project([bbox[0], bbox[1]]);
      const topRightPointOnScreen = this._map.project([bbox[2], bbox[3]]);
      // Select all the features in the envelope of the last two clics
      mapboxBbox = [
        [bottomLeftPointOnScreen.x, bottomLeftPointOnScreen.y],
        [topRightPointOnScreen.x, topRightPointOnScreen.y],
      ];
    }

    const features = this._map.queryRenderedFeatures(mapboxBbox, { layers: layerNames });

    return features;
  }

  filterFeaturesFromClickableLayers(feature, features) {
    // Filtered features selected with all the lit-idr previously selected
    const newLitIdrs = feature.properties['data-lit_idr'].split(';');
    const latestLitIdr = this.clientStructuresClicked.data.at(-1).litIdrs;
    const litIdrsToSelect = latestLitIdr + newLitIdrs;
    const filteredFeatures = features.filter(
      (f) => f.properties['data-lit_idr'].split(';').some(
        (idr) => litIdrsToSelect.includes(idr),
      ),
    );
    this.clientStructuresClicked.data.push({
      litIdrs: newLitIdrs,
      pylonsIdentifier: retrievePylonsIdentifierFromFeature(feature),
    });

    return filteredFeatures;
  }

  retrieveCompletingFeaturesFromClickableLayers(layerNames) {
    const lastClientStructureClicked = this.clientStructuresClicked.data.at(-1);
    const currentLitIdrs = lastClientStructureClicked.litIdrs.sort();
    const concernedData = this.clientStructuresClicked.data.filter(
      (data) => JSON.stringify(data.litIdrs.sort()) === JSON.stringify(currentLitIdrs),
    );
    const pylonIdentifiers = concernedData.map((data) => data.pylonsIdentifier).flat();
    const minPylonIdentifer = Math.min(...pylonIdentifiers);
    const maxPylonIdentifer = Math.max(...pylonIdentifiers);

    const features = this.retrieveElementsFromClickableLayers(layerNames);
    const completingFeatures = features.filter(
      (f) => f.properties['data-lit_idr'].split(';').some(
        (idr) => currentLitIdrs.includes(idr),
      ) && retrievePylonsIdentifierFromFeature(f).some(
        (pylonIdentifier) => (
          pylonIdentifier > minPylonIdentifer && pylonIdentifier < maxPylonIdentifer
        ),
      ),
    );

    return completingFeatures;
  }

  forceClickableGeometryToMultiPolygon() {
    // Force the geometry to be a MultiPolygon
    let multiPolygon;
    if (
      turf.getType(this.clientStructuresClicked.geometryBuffered) === 'MultiPolygon'
    ) {
      multiPolygon = this.clientStructuresClicked.geometryBuffered;
    } else {
      multiPolygon = turf.multiPolygon(
        [this.clientStructuresClicked.geometryBuffered.geometry.coordinates],
      );
    }

    return multiPolygon;
  }

  _actionOnClickableLayersInWriteMode(layerNames, e) {
    // Action only if a first client structure has been selected in READ mode
    if (this.clientStructuresClicked) {
      const [feature, popPoint] = this.getClientStructureAtClick(layerNames, e);
      if (feature && feature?.properties['data-type'] !== 'site') {
        const features = this.retrieveElementsFromClickableLayers(layerNames, popPoint);
        if (features.length) {
          const filteredFeatures = this.filterFeaturesFromClickableLayers(feature, features);
          const completingFeatures = this.retrieveCompletingFeaturesFromClickableLayers(layerNames);
          const newfeatures = filteredFeatures.concat(completingFeatures);
          // Add all the selected geometries to the current geometry
          newfeatures.forEach((f) => {
            const geometry = (
              f.properties['data-type'] === 'line'
                ? turf.lineString(f.geometry.coordinates)
                : turf.point(f.geometry.coordinates)
            );
            const geometryBuffered = turf.buffer(geometry, 50, { units: 'meters' });
            this.clientStructuresClicked.geometryBuffered = turf.union(
              this.clientStructuresClicked.geometryBuffered,
              geometryBuffered,
            );
          });
          const multiPolygon = this.forceClickableGeometryToMultiPolygon();
          // Update the area in the store and draw on the map
          this._updateAreaFromClientStructure(multiPolygon.geometry);
        }
      }
    }
  }

  _actionOnClickableLayers(layerNames, e) {
    this._mapDoubleClick = false;
    setTimeout(() => {
      if (
        !this._mapDoubleClick
        && !this._isRulerActivated
        && !e.originalEvent.consumed
        && this._mode === MODE.READ
      ) {
        this._actionOnClickableLayersInReadMode(layerNames, e);
      }
    }, 250);
  }

  _setLayerCursorPointerOnHover(layerName) {
    this._map.on('mouseenter', layerName, this._pointerOnMouseEnter);
    this._map.on('mouseleave', layerName, this._pointerOffMouseLeave);
  }

  _removeLayerCursorPointerOnHover(layerName) {
    this._map.off('mouseenter', layerName, this._pointerOnMouseEnter);
    this._map.off('mouseleave', layerName, this._pointerOffMouseLeave);
  }

  removeDataLayers() {
    let layers = [];
    try {
      /* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: true}}] */
      layers = this._map.getStyle().layers;
    } catch (error) {
      // If no layers no need to remove anything.
    }
    layers = layers.filter((layer) => layer.id.startsWith(LAYER_SOURCE_PREFIX));
    const layerIds = layers.map((layer) => layer.id);
    layerIds.forEach((layerId) => {
      this._map.removeLayer(layerId);
    });
  }

  createMapboxGeojsonLayer(layer, sourceData) {
    const mapboxLayer = {
      layout: {},
      paint: {},
    };
    mapboxLayer.id = layer.mapboxLayerId;
    const mapboxLayerSource = this._map.getSource(layer.mapboxLayerId);
    if (mapboxLayerSource === undefined) {
      mapboxLayer.source = {
        type: 'geojson',
        data: sourceData,
      };
    } else {
      mapboxLayerSource.setData(sourceData);
      mapboxLayer.source = mapboxLayerSource.id;
    }
    const layerStyleFromLayerId = LAYER_STYLES[layer.identifier];
    const layerStyleFromStyleId = LAYER_STYLES[layer.styleIdentifier];
    let mapboxLayerStyle;
    // Create a deep copy of object
    if (layerStyleFromLayerId) {
      mapboxLayerStyle = JSON.parse(JSON.stringify(layerStyleFromLayerId));
    } else if (layerStyleFromStyleId) {
      mapboxLayerStyle = JSON.parse(JSON.stringify(layerStyleFromStyleId));
    } else {
      mapboxLayerStyle = JSON.parse(JSON.stringify(DEFAULT_POLYGON_STYLE));
    }
    Object.keys(mapboxLayerStyle.mapboxStyleOptions).forEach((key) => {
      mapboxLayer[key] = mapboxLayerStyle.mapboxStyleOptions[key];
    });
    mapboxLayer.layout.visibility = (
      layer.visible || mapboxLayerStyle.persistent ? 'visible' : 'none'
    );
    return mapboxLayer;
  }

  createMapboxRasterLayer(layer) {
    const mapboxLayer = {
      id: layer.mapboxLayerId,
      type: 'raster',
    };

    const layerStyleFromLayerId = LAYER_STYLES[layer.identifier];
    let mapboxLayerStyle;
    // Create a deep copy of object
    if (layerStyleFromLayerId) {
      mapboxLayerStyle = JSON.parse(JSON.stringify(layerStyleFromLayerId));
    } else {
      mapboxLayerStyle = JSON.parse(JSON.stringify(DEFAULT_RASTER_STYLE));
    }

    const mapboxLayerSource = this._map.getSource(layer.mapboxLayerId);
    if (mapboxLayerSource === undefined) {
      const rasterSource = {
        type: 'raster',
        tiles: [layer.url],
        tileSize: 256,
      };
      Object.keys(mapboxLayerStyle.sourceOptions).forEach((key) => {
        rasterSource[key] = mapboxLayerStyle.sourceOptions[key];
      });
      mapboxLayer.source = rasterSource;
    }
    Object.keys(mapboxLayerStyle.mapboxStyleOptions).forEach((key) => {
      mapboxLayer[key] = mapboxLayerStyle.mapboxStyleOptions[key];
    });
    mapboxLayer.layout.visibility = layer.visible ? 'visible' : 'none';
    if (layer.opacity !== null) {
      mapboxLayer.paint = { 'raster-opacity': layer.opacity };
    }

    return mapboxLayer;
  }

  /* eslint-disable class-methods-use-this */
  createMapboxVectorLayer(layer) {
    const mapboxLayer = {
      id: layer.mapboxLayerId,
      type: layer.layerType,
      source: {
        type: layer.layerType === 'raster' ? layer.layerType : layer.type,
        url: layer.url,
      },
      'source-layer': layer.sourceName,
      layout: {},
    };
    if (layer.style) {
      Object.keys(layer.style).forEach((key) => {
        mapboxLayer[key] = layer.style[key];
      });
    }

    if (layer.category === 'mobile_network') {
      mapboxLayer.layout.visibility = 'none';
      if (layer.opacity !== null) {
        if (layer.layerType === 'fill') mapboxLayer.paint = { 'fill-opacity': layer.opacity * 0.1 };
        if (layer.layerType === 'symbol') mapboxLayer.paint = { 'icon-opacity': layer.opacity };
      }
    } else {
      mapboxLayer.layout.visibility = layer.visible ? 'visible' : 'none';
    }

    return mapboxLayer;
  }

  setLayers(layers) {
    const that = this;
    that._map.customLayersLoaded = false;
    that.removeDataLayers();
    that.layers = [];
    Object.keys(layers).forEach((layerId) => {
      const layerStyle = LAYER_STYLES[layerId];
      const layerPersistency = layerStyle === undefined
        ? DEFAULT_POLYGON_STYLE.persistent : layerStyle.persistent;
      const layer = new DataLayer({
        identifier: layerId,
        name: layers[layerId].name,
        url: layers[layerId].url,
        thumbnailUrl: layers[layerId].thumbnailUrl,
        type: layers[layerId].sourceType,
        layerType: layers[layerId].layerType,
        sourceName: layers[layerId].sourceName,
        style: layers[layerId].style,
        displayPriority: layers[layerId].displayPriority,
        persistent: layerPersistency,
        visible: layers[layerId].visible,
        styleIdentifier: layers[layerId].styleIdentifier,
        status: layers[layerId].status,
        opacity: layers[layerId].opacity,
        category: layers[layerId].category,
      });
      that.layers.push(layer);
    });
    that.layers.sort((a, b) => a.displayPriority - b.displayPriority);
    that._map.customLayersLoaded = true;
  }

  _addLayers() {
    this.layers.forEach((layer) => {
      if (layer.status !== 'failed') {
        let mapboxLayer = null;
        if (layer.type === 'geojson') {
          mapboxLayer = this.createMapboxGeojsonLayer(
            layer,
            this.sourcesData[layer.identifier] || emptySourceData,
          );
        } else if (layer.type === 'raster') {
          mapboxLayer = this.createMapboxRasterLayer(layer);
        } else if (layer.type === 'vector') {
          mapboxLayer = this.createMapboxVectorLayer(layer);
        } else {
          return;
        }
        this._map.onLoad(() => {
          this._map.addLayer(mapboxLayer);
        });
      }
    });
    if (this.editExclusionZone) {
      this._map.onLoad(() => {
        const geojson = this._drawControl.getAll();
        this._map.removeControl(this._drawControl);
        this._map.addControl(this._drawControl);
        this.setArea(geojson);
        // When exclusion zone is editing, Flights group layers is not visible by default
        FLIGHT_EXCLUSION_LAYER_IDENTIFIERS.forEach((identifier) => this.showLayer(identifier));
      });
    }
  }

  drawBufferSizeExclusionZoneFirstPlan() {
    if (this.editExclusionZone) {
      const layer = this.layers.find(
        (l) => l.identifier === 'FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_CENTERS_LAYER',
      );
      if (layer) {
        this._map.moveLayer(LAYER_SOURCE_PREFIX + layer.identifier);
      }
    }
  }

  updateSourcesData(sourceId, sourceData) {
    this.sourcesData[sourceId] = sourceData;
    const that = this;
    that._map.onLoad(() => {
      that._addEmptySource(LAYER_SOURCE_PREFIX + sourceId);
      const source = that._map.getSource(LAYER_SOURCE_PREFIX + sourceId);
      source.setData(sourceData);
      this.drawBufferSizeExclusionZoneFirstPlan();
    });
  }

  hideLayer(layerId) {
    const layer = this.layers.find((l) => l.identifier === layerId && l.status !== 'failed');
    if (layer) {
      layer.hide(this._map);
      layer.visible = false;
      if (this._layerPopups[layerId]) {
        this.removeLayerPopups(layerId);
      }
    }
  }

  showLayer(layerId) {
    const layer = this.layers.find((l) => l.identifier === layerId && l.status !== 'failed');
    if (layer && !this.layerIdsCategoryUnvisible[layer.category].includes(layerId)) {
      layer.show(this._map);
      layer.visible = true;
    }
  }

  moveLayer(layerId) {
    this._map.moveLayer(LAYER_SOURCE_PREFIX + layerId);
  }

  showCustomLayer(layerId) {
    const layer = this._customLayers.find((l) => l.id === layerId);
    if (layer) {
      this.showLayer(layer.identifier);
      this.showLayer(formatCustomLayerOutlineIdentifier(layer.identifier));
      this.showLayer(formatCustomLayerLabelsIdentifier(layer.identifier));
      this.toggleCheckboxOn(layer.identifier);
    }
  }

  hideCustomLayer(layerId) {
    const layer = this._customLayers.find((l) => l.id === layerId);
    if (layer) {
      this.hideLayer(layer.identifier);
      this.hideLayer(formatCustomLayerOutlineIdentifier(layer.identifier));
      this.hideLayer(formatCustomLayerLabelsIdentifier(layer.identifier));
      this.toggleCheckboxOff(layer.identifier);
    }
  }

  disableCustomLayer(layerId) {
    const layer = this._customLayers.find((l) => l.id === layerId);
    if (layer) {
      this.disableCheckbox(layer.identifier);
    }
  }

  enableCustomLayer(layerId) {
    const layer = this._customLayers.find((l) => l.id === layerId);
    if (layer) {
      this.enableCheckbox(layer.identifier);
    }
  }

  toggleCheckboxOn(id) {
    const checkboxHandle = this._controlLayer.checkboxes[id];
    checkboxHandle.checkbox.checked = true;
  }

  toggleCheckboxOff(id) {
    const checkboxHandle = this._controlLayer.checkboxes[id];
    checkboxHandle.checkbox.checked = false;
  }

  disableCheckbox(id) {
    const checkboxHandle = this._controlLayer.checkboxes[id];
    checkboxHandle.checkbox.disabled = true;
  }

  enableCheckbox(id) {
    const checkboxHandle = this._controlLayer.checkboxes[id];
    checkboxHandle.checkbox.disabled = false;
  }

  setMapMode({ mode, tab }) {
    const that = this;
    return new Promise((resolve, reject) => {
      function setMapModeWithRetry({ mapMode, activeTab, retry = 3, error = null }) {
        try {
          if (retry === 0 && error != null) {
            reject(error);
          } else if (mapMode === MODE.WRITE) {
            that.setModeWrite(activeTab);
            that._updateConstraintsPoint(null);
            that._updateOnSpecificMapClic(null, null);
            resolve();
          } else if (mapMode === MODE.READ) {
            that.setModeRead(activeTab);
            resolve();
          } else {
            reject(new Error(`Unknown map Mode ${mapMode}`));
          }
        } catch (e) {
          setTimeout(() => {
            setMapModeWithRetry({
              mapMode,
              activeTab,
              retry: retry - 1,
              error: e,
            });
          }, 2000);
        }
      }
      setMapModeWithRetry({ mapMode: mode, activeTab: tab });
    });
  }

  hideLayerGroup(layerHandle, { disable }) {
    layerHandle.checkbox.checked = false;
    if (disable) {
      layerHandle.checkbox.disabled = true;
    }
    this.setLayerGroupVisibility(layerHandle.layerIds, 'none');
  }

  showLayerGroup(layerHandle) {
    layerHandle.checkbox.checked = true;
    layerHandle.checkbox.disabled = false;
    this.setLayerGroupVisibility(layerHandle.layerIds, 'visible');
  }

  setOptionsDrawControl() {
    if (this.clientStructuresClicked !== undefined) {
      this._drawControl.options.controls = {
        polygon: false,
        trash: false,
      };
    } else {
      this._drawControl.options.controls = {
        polygon: true,
        trash: true,
      };
    }

    // Display icon to control areas history
    this.areasHistory = [];
    if (this._mode === MODE.READ) {
      this._map.removeControl(this._controlAreasHistory);
      document.removeEventListener('keydown', this.keyDownRestoreAreaListener);
    } else {
      this._controlAreasHistory = new MapAreasHistoryControl();
      this._map.addControl(this._controlAreasHistory);
      this.keyDownRestoreAreaListener = this.restoreAreaAtKeyEvent.bind(this);
      document.addEventListener('keydown', this.keyDownRestoreAreaListener);
    }
  }

  switchModeDrawControl() {
    if (this.clientStructuresClicked !== undefined) {
      this._drawControl.changeMode('simplified_flight_form');
    }
  }

  moveLayersControlToBottomOfControls() {
    const topRightControls = this._map.getContainer().querySelector('.mapboxgl-ctrl-top-right');
    const layersControlElement = topRightControls.querySelector(
      '.mapboxgl-ctrl.mapboxgl-ctrl-group.layers-control',
    );
    topRightControls.appendChild(layersControlElement);
  }

  setModeWrite(activeTab) {
    this._mode = MODE.WRITE;
    this.setOptionsDrawControl();
    this._map.addControl(this._drawControl);
    this.switchModeDrawControl();
    this.moveLayersControlToBottomOfControls();

    if (activeTab === APPLICATION_TAB.MISSION) {
      this.hideLayerGroup(this._controlLayer.checkboxes.Flights, { disable: true });
      this.showLayerGroup(this._controlLayer.checkboxes.CTRAreas);
    }
    if (activeTab === APPLICATION_TAB.MISSION_EXCLUSION_ZONE) {
      this.editExclusionZone = true;
      this.hideLayerGroup(this._controlLayer.checkboxes.Flights, { disable: true });
      this.hideLayerGroup(this._controlLayer.checkboxes.CTRAreas, { disable: false });
    }
  }

  setModeRead(activeTab) {
    this.editExclusionZone = false;
    this._mode = MODE.READ;
    this._map.removeControl(this._drawControl);
    this._map.areaToEdit = null;
    this.clientStructuresClicked = undefined;
    this.setOptionsDrawControl();

    if (activeTab === APPLICATION_TAB.MISSION) {
      this.showLayerGroup(this._controlLayer.checkboxes.Flights);
    }
    if (activeTab === APPLICATION_TAB.MISSION_EXCLUSION_ZONE) {
      this.showLayerGroup(this._controlLayer.checkboxes.Flights);
      this.showLayerGroup(this._controlLayer.checkboxes.CTRAreas);
    }
  }

  hideFlights() {
    this.hideLayerGroup(this._controlLayer.checkboxes.Flights, { disable: false });
  }

  showFlights() {
    this.showLayerGroup(this._controlLayer.checkboxes.Flights);
  }

  showTraces() {
    if (!this._controlLayer) return;
    this.showLayerGroup(this._controlLayer.checkboxes.DroneTrackingTrace);
  }

  updateBoundingBox(data) {
    this._map.onLoad(() => {
      this._map.fitBounds(data, { maxZoom: 22, padding: 80 });
      this._map.once('movestart', () => {
        this._setBoundingBoxIsUpdating(true);
      });
      this._map.once('moveend', () => {
        this._map.once('idle', () => {
          this._setBoundingBoxIsUpdating(false);
        });
      });
    });
  }

  zoomTo(coords, zoom = 10) {
    this._map.flyTo({ center: coords, zoom });
  }

  resize() {
    this._map.resize();
  }

  setArea(geojson, storeArea = true) {
    this._map.areaToEdit = geojson;
    this._drawControl.deleteAll();
    if (geojson) {
      this._drawControl.add(geojson);
    }
    this._updateArea(storeArea);
  }

  selectArea(id) {
    if (this._drawControl) {
      this._drawControl.changeMode('simple_select', { featureIds: [id] });
    }
  }

  deleteArea(id) {
    if (this._drawControl) {
      this._drawControl.delete([id]);
      this._updateArea();
    }
  }

  setDrawLayerStyleProperty({ property, value }) {
    if (this._drawControl) {
      const featureCollection = this._drawControl.getAll();
      featureCollection.features.forEach((feature) => {
        this._drawControl.setFeatureProperty(feature.id, property, value);
        const updatedFeature = this._drawControl.get(feature.id);
        this._drawControl.add(updatedFeature);
      });
    }
  }

  getFeatureById(layerId, id) {
    if (
      id !== null
      && this.sourcesData[layerId]
      && this.sourcesData[layerId].features
      && this.sourcesData[layerId].features.filter((f) => f.id === id)
      && this.sourcesData[layerId].features.filter((f) => f.id === id)[0]
      && this.sourcesData[layerId].features.filter((f) => f.id === id)[0].geometry
    ) {
      return this.sourcesData[layerId].features.find((f) => f.id === id);
    }
    return undefined;
  }

  setLayerEmptyPopups(layerId) {
    this._layerPopups[layerId] = {};
  }

  removeLayerPopups(layerId) {
    if (this._layerPopups[layerId]) {
      Object.keys(this._layerPopups[layerId]).forEach((popupId) => {
        this._closeLayerPopup({ layerId, popupId });
      });
      this._layerPopups[layerId] = {};
    }
  }

  setLayerPopupOnClick({ layerId, propertyId }) {
    if (layerId) {
      const layerName = `${LAYER_SOURCE_PREFIX}${layerId}`;
      this._setLayerCursorPointerOnHover(layerName);
      this._map.onLoad(() => {
        this._map.on('click', layerName, (e) => {
          e.originalEvent.consumed = true;
          const feature = e.features[0];
          this.toggleLayerMultiplePopups({
            layerId,
            featureId: feature.properties[propertyId],
            coordinates: (
              feature.geometry.type === 'Point'
                ? feature.geometry.coordinates
                : e.lngLat
            ),
          });
        });
      });
    }
  }

  setLayerPopupOnHover({
    layerId,
    propertyId,
    withLayerPrefix = true,
    withTitle = false,
    uniquePopupId = false,
  }) {
    if (layerId) {
      const layerName = withLayerPrefix ? `${LAYER_SOURCE_PREFIX}${layerId}` : layerId;
      this._setLayerCursorPointerOnHover(layerName);
      this._map.onLoad(() => {
        this._map.on('mouseenter', layerName, (e) => {
          const feature = e.features[0];
          this.toggleLayerSinglePopup({
            layerId,
            featureId: feature.properties[propertyId],
            coordinates: (
              feature.geometry.type === 'Point'
                ? feature.geometry.coordinates
                : e.lngLat
            ),
            popupTitle: withTitle ? feature.properties.title : '',
            uniquePopupId,
          });
        });
        this._map.on('mouseleave', layerName, () => this.removeLayerPopups(layerId));
      });
    }
  }

  toggleLayerMultiplePopups({ layerId, featureId, coordinates }) {
    // Open new popup on a layer's feature if not already openned or close it
    // Multiple popups can be openned at the same time for different features

    const popupId = featureId;

    if (!this._layerPopups[layerId]) {
      this.setLayerEmptyPopups(layerId);
    }
    const currentPopup = this._getLayerPopup({ layerId, popupId });
    if (!currentPopup) {
      this._addLayerPopup({ layerId, popupId, coordinates });
    } else {
      this._closeLayerPopup({ layerId, popupId });
    }
  }

  toggleLayerSinglePopup({
    layerId,
    featureId,
    coordinates,
    popupTitle = '',
    uniquePopupId = false,
    withMarker = false,
    movable = true,
  }) {
    // Open new popup on a layer's feature or close it if no featureId
    // Only a single popup can be openned at a time (previous feature popups are moved or closed)
    // If movable option is enabled popups can move from feature to next one at toggle
    // If movable option is disabled popups will be closed if already openned at toggle

    const popupId = uniquePopupId ? `POPUP-${featureId}` : layerId;
    if (!this._layerPopups[layerId]) {
      this.setLayerEmptyPopups(layerId);
    }
    const currentPopup = this._getLayerPopup({ layerId, popupId });
    if (!featureId) {
      this._closeLayerPopup({ layerId, popupId });
    } else if (!currentPopup) {
      this._addLayerPopup({ layerId, popupId, coordinates, popupTitle, withMarker });
    } else if (!movable) {
      this._closeLayerPopup({ layerId, popupId });
    } else {
      currentPopup.popup.setLngLat(coordinates);
      if (currentPopup.marker) {
        currentPopup.marker.setLngLat(coordinates);
      }
    }
  }

  _addLayerPopup({ layerId, popupId, coordinates, popupTitle = '', withMarker = false }) {
    // Add popup to map according to layer popup options
    // with possibility to add a title or place popup on a marker

    const layerPopups = this._layerPopups[layerId];
    const popupStyle = POPUPS_STYLES[layerId] || POPUPS_STYLES.DEFAULT;
    const popup = new mapboxgl.Popup(popupStyle.popup)
      .setHTML(`<div id="${popupId}"><h4>${popupTitle}</h4></div>`);

    layerPopups[popupId] = { popup };
    this._addPopupCallback(layerId, popupId);

    if (withMarker) {
      const marker = new mapboxgl.Marker(popupStyle.marker)
        .setLngLat(coordinates)
        .setPopup(popup)
        .addTo(this._map)
        .togglePopup();
      layerPopups[popupId].marker = marker;
    } else {
      popup
        .setLngLat(coordinates)
        .addTo(this._map);
    }

    popup.on('close', () => {
      this._removePopupCallback(layerId, popupId);
      if (layerPopups[popupId]) {
        const { marker } = layerPopups[popupId];
        delete layerPopups[popupId];
        if (marker) {
          marker.remove();
        }
      }
    });
  }

  _closeLayerPopup({ layerId, popupId }) {
    const layerPopup = this._getLayerPopup({ layerId, popupId });
    if (layerPopup && layerPopup.popup) {
      layerPopup.popup.remove();
    }
  }

  _getLayerPopup({ layerId, popupId }) {
    return this._layerPopups[layerId] ? this._layerPopups[layerId][popupId] : undefined;
  }

  refreshLayerPopup({ layerId, popupId }) {
    // Refresh popup position and bounds
    const layerPopup = this._getLayerPopup({ layerId, popupId });
    if (layerPopup) {
      layerPopup.popup._update();
    }
  }

  moveOpenedLayerPopups({ layerId, propertyId, newfeatures }) {
    if (this._layerPopups[layerId]) {
      // Get features linked to already openned popups
      const popups = this._layerPopups[layerId];
      Object.keys(popups).forEach((popupId) => {
        const feature = newfeatures.find((f) => f.properties[propertyId] === popupId);
        if (feature) {
          // If feature found update popup position using new geom
          popups[popupId].popup.setLngLat(feature.geometry.coordinates);
        } else {
          // If feature not found close popup
          this._closeLayerPopup({ layerId, popupId });
        }
      });
    }
  }

  addMarker(
    markerId, markerIcon, markerAnchor, point, restrictToCurrentArea, updateForbiddenZone,
  ) {
    const marker = (
      new mapboxgl.Marker({
        draggable: this._mode === MODE.WRITE,
        anchor: markerAnchor,
        element: Map.makeMarkerIcon(markerIcon),
      })
        .setLngLat([point.coordinates[0], point.coordinates[1]])
        .addTo(this._map)
    );
    marker._element.id = `${markerId}_${this.lastMarkerId}`;
    if (!this._mapMarkers[markerId]) {
      this._mapMarkers[markerId] = [];
    }
    this._mapMarkers[markerId].push(marker);
    if (restrictToCurrentArea) {
      this.restrictMarkerToCurrentArea(marker);
    }
    if (updateForbiddenZone) {
      this.updateForbiddenZoneAtDrag(marker);
    }
    this.lastMarkerId += 1;
  }

  removeLastMarker(markerId) {
    if (this._mapMarkers[markerId]) {
      const lastMarker = this._mapMarkers[markerId].pop();
      lastMarker.remove();
    }
  }

  removeAllMarkers(markerId) {
    if (this._mapMarkers[markerId]) {
      this._mapMarkers[markerId].forEach((marker) => {
        marker.remove();
      });
      this._mapMarkers[markerId] = [];
    }
  }

  restrictMarkerToCurrentArea(marker) {
    let previousPosition = marker.getLngLat();
    marker.on('dragend', () => {
      const newPosition = marker.getLngLat();
      const result = turf.within(
        turf.point([newPosition.lng, newPosition.lat]),
        this._drawControl.getAll(),
      );
      if (!(result && result.features && result.features.length > 0)) {
        marker.setLngLat(previousPosition);
      } else {
        previousPosition = marker.getLngLat();
      }
    });
  }

  removeMarkersOutsideFlightArea() {
    const markers = this._mapMarkers;
    let landingPoints = [];
    if ('landing-point' in markers) {
      landingPoints = markers['landing-point'];
    }
    let takeOffPoints = [];
    if ('take-off-point' in markers) {
      takeOffPoints = markers['take-off-point'];
    }
    const droneMarkers = landingPoints.concat(takeOffPoints);
    droneMarkers.forEach((marker) => {
      const position = marker.getLngLat();
      const result = turf.within(
        turf.point([position.lng, position.lat]),
        this._drawControl.getAll(),
      );
      if (!(result && result.features && result.features.length > 0)) {
        const type = marker._element.id.split('_')[0];
        const index = this._mapMarkers[type].findIndex(
          (m) => m._element.id === marker._element.id,
        );
        this._mapMarkers[type].splice(index, 1);
        marker.remove();
        this._updateMarkerCount(type, -1);
      }
    });
  }

  updateForbiddenZoneAtDrag(marker) {
    marker.on('dragend', () => {
      this._updateForbiddenZone(this._drawControl.getAll());
    });
  }

  getMapMarkers() {
    return this._mapMarkers;
  }

  static makeMarkerIcon(markerIcon) {
    const icon = document.createElement('div');
    icon.style.backgroundImage = `url("${markerIcon}")`;
    icon.style.backgroundSize = 'cover';
    icon.style.width = '42px';
    icon.style.height = '42px';
    icon.style.cursor = 'pointer';
    icon.style.borderRadius = '50%';
    return icon;
  }

  restoreAreaAtKeyEvent(event) {
    if (event.ctrlKey && ['z', 'Z'].includes(event.key)) this.restoreArea();
  }

  restoreArea() {
    if (this._mode === MODE.WRITE && this.areasHistory.length > 1) {
      // Remove current position
      this.areasHistory.pop();
      // Retrieve previous area
      const lastArea = this.areasHistory[this.areasHistory.length - 1];
      // Draw previous area
      this.setArea(lastArea, false);
      this.restoreClientStructuresClicked(lastArea);
    }
  }

  restoreClientStructuresClicked(lastArea) {
    if (!this.clientStructuresClicked) return;
    // Update geometry
    this.clientStructuresClicked.geometryBuffered = turf.feature(
      featureCollectionToMultiPolygon(lastArea),
    );
    if (this.clientStructuresClicked.data.length > 1) {
      // Remove last litIds clicked
      this.clientStructuresClicked.data.pop();
    }
  }
}

export default Map;

function clearGeojsonProperties(geojson) {
  // Remove geojson properties for mapbox use as a static image overlay
  const cleaned = geojson;
  if (cleaned.properties) {
    cleaned.properties = {};
  }
  if (cleaned.features) {
    cleaned.features.map((f) => {
      const cleanedF = f;
      cleanedF.properties = {};
      return cleanedF;
    });
  }
  return cleaned;
}

export function getStaticImageUrl({
  overlayGeojson,
  centerLon,
  centerLat,
  width = 1000,
  height = 300,
  clearProperties = true,
}) {
  let url = `https://api.mapbox.com/styles/v1/${MAPBOX_USERNAME}/${MAPBOX_DEFAULT_STYLE_ID}/static/`;

  if (overlayGeojson) {
    let geojson = turf.truncate(overlayGeojson, { precision: 5, coordinates: 2, mutate: false });
    if (clearProperties) {
      geojson = clearGeojsonProperties(geojson);
    }
    let encoded = encodeURI(JSON.stringify(geojson));
    encoded = encoded.replaceAll('#', '%23');
    url += `geojson(${encoded})/auto/`;
  } else {
    url += `${centerLon},${centerLat},13/`;
  }

  url += `${width}x${height}@2x?access_token=${MAPBOX_TOKEN}`;
  return url;
}
