<template>
  <v-container class="px-0 px-sm-2">
    <!-- Success message for new subscriptions -->
    <v-row
      align="center"
      justify="center"
      no-gutters
    >
      <v-alert
        v-if="subscriptionState === 'success'"
        :value="true"
        dismissible
        text
        type="success"
        color="green darken-1"
        max-width="580"
      >
        <translate>Paiement successful, we thank you for your suscription.</translate>
      </v-alert>
    </v-row>
    <v-skeleton-loader
      v-if="userRefreshing || loadingPilots"
      :loading="userRefreshing || loadingPilots"
      type="image"
    />
    <v-row
      v-else
      justify="center"
      no-gutters
    >
      <v-slide-group
        :value="currentFormuleIndex"
        center-active
        show-arrows
      >
        <v-slide-item
          v-for="(subscriptionPlan, index) in subscriptionPlans"
          :key="index"
          v-slot="{ active }"
        >
          <div>
            <div
              v-if="subscriptionPlan.display"
              class="fill-height d-flex flex-column pa-2"
            >
              <div class="subscription-plan__header">
                <span
                  v-if="active"
                  class="primary--text body-1 font-weight-medium"
                >
                  <translate>Your current formula</translate>
                </span>
              </div>
              <v-card
                :color="getCardColor(active)"
                class="d-flex flex-column flex-grow-1"
                min-height="360"
                width="280"
              >
                <v-card-title class="d-flex justify-center">
                  <span :class="getTextColor(active)">
                    {{ subscriptionPlan.name }}
                    <template v-if="isTrialPeriod && subscriptionPlan.code === 'SOLO'">
                      <translate :class="getTextColor(active)">(Trial period)</translate>
                    </template>
                  </span>
                </v-card-title>
                <v-card-text>
                  <div>
                    <v-row
                      justify="center"
                      class="text-h3"
                      :class="getTextColor(active)"
                    >
                      {{ subscriptionPlan.price }}
                    </v-row>
                    <v-row
                      justify="center"
                      class="fix-height"
                      :class="active ? 'white--text' : ''"
                    >
                      {{ subscriptionPlan.priceInfo }}
                    </v-row>
                  </div>
                  <div class="d-flex flex-wrap mt-5">
                    <p
                      v-html="subscriptionPlan.mainDescription"
                      class="flex-grow-1 text-center font-weight-medium"
                      :class="getTextColor(active)"
                    />
                    <span
                      v-html="subscriptionPlan.description"
                      :class="getTextColorAdditionalInfo(active)"
                    />
                  </div>
                  <div v-if="active && (isTrialPeriod || cancelDate)">
                    <v-divider :dark="active" />
                    <div
                      v-if="isTrialPeriod"
                      class="d-flex flex-wrap justify-center"
                      :class="getTextColorAdditionalInfo(active)"
                    >
                      <translate>Trial end:</translate>
                      <span class="font-italic ml-1">
                        {{ trialEndDate }}
                      </span>
                    </div>
                    <div v-if="cancelDate">
                      <div
                        class="d-flex flex-wrap justify-center"
                        :class="getTextColorAdditionalInfo(active)"
                      >
                        <translate>Subscription cancelled at</translate>
                        &nbsp;
                        <span>
                          {{ cancelDate }}
                        </span>
                      </div>
                    </div>
                  </div>
                </v-card-text>
                <v-spacer />
                <v-card-actions class="d-flex justify-center">
                  <template v-if="subscriptionPlan.displayButton">
                    <v-btn
                      small
                      class="px-4 my-2"
                      :outlined="getButtonOulined(active)"
                      :color="getColorButton(active)"
                      @click="changeSubscriptionPlan(subscriptionPlan.buttonValue)"
                    >
                      <span :class="getColorTextButton(active)">
                        {{ subscriptionPlan.buttonText }}
                      </span>
                    </v-btn>
                  </template>
                </v-card-actions>
              </v-card>
            </div>
          </div>
        </v-slide-item>
      </v-slide-group>
    </v-row>
    <v-row
      align="center"
      justify="center"
      class="my-2 px-2"
      no-gutters
    >
      <p class="body-2 info--text font-italic text-center">
        <!-- Change subscription info for enterprise and formation -->
        <template v-if="userFormationOrEntreprise">
          <translate>
            Please contact us at support@clearance.aero for any request about subscription changes.
          </translate>
        </template>
        <!-- More details link -->
        <template v-else>
          <span v-html="moreDetailsLink" />
        </template>
      </p>
    </v-row>
    <v-row
      align="center"
      justify="center"
      class="mt-2"
      no-gutters
    >
      <AffiliateInformation
        v-if="subscription.affiliate_code"
        :affiliate-code="subscription.affiliate_code"
        class="ma-2"
      />
      <InsurancePartner
        :insurance-code="userData.insurance_code"
        :user-subscribed="!userNotSubscribed"
        class="ma-2"
      />
    </v-row>
    <!-- Dialog subscription -->
    <v-dialog
      v-model="dialogSubscription"
      persistent
      max-width="620"
    >
      <SubscriptionPlan
        :formules="formules"
        :oldSubscriptionPlan="userOldPlans"
        @close="dialogSubscription=false"
        @change-subscription="(targetFormule) => openSubscriptionChange(targetFormule)"
      />
    </v-dialog>
    <!-- Cancellation dialog -->
    <v-dialog
      v-model="dialogSubscriptionCancel"
      persistent
      max-width="620"
    >
      <SubscriptionPlanCancel
        :current-formule="currentFormuleData"
        @close="dialogSubscriptionCancel=false"
      />
    </v-dialog>
    <!-- Confirmation dialog -->
    <v-dialog
      v-model="dialogSubscriptionChange"
      persistent
      max-width="820"
    >
      <SubscriptionPlanChange
        v-if="dialogSubscriptionChange"
        :current-formule="currentFormuleData"
        :target-formule="targetFormuleData"
        @change-confirmed="closeSubscriptionChange()"
        @change-cancelled="closeSubscriptionChange()"
      />
    </v-dialog>
    <!-- Reactivate dialog -->
    <v-dialog
      v-model="dialogSubscriptionReactivate"
      persistent
      max-width="620"
    >
      <SubscriptionPlanReactivate
        :current-formule="currentFormuleData"
        @close="dialogSubscriptionReactivate=false"
      />
    </v-dialog>
    <!-- Enterprise dialog -->
    <v-dialog
      v-model="dialogSubscriptionEnterprise"
      persistent
      max-width="620"
    >
      <SubscriptionPlanEnterprise @close="dialogSubscriptionEnterprise=false" />
    </v-dialog>
  </v-container>
</template>

<script>
import { GET_SIMPLIFIED_PILOTS_NS } from '@/store/pilots';

import AffiliateInformation from '@/components/Settings/Dronists/Subscription/AffiliateInformation.vue';
import InsurancePartner from '@/components/Settings/Dronists/Subscription/InsurancePartner.vue';
import SubscriptionPlan from '@/components/Settings/Dronists/Subscription/SubscriptionPlan.vue';
import SubscriptionPlanCancel from '@/components/Settings/Dronists/Subscription/SubscriptionPlanCancel.vue';
import SubscriptionPlanChange from '@/components/Settings/Dronists/Subscription/SubscriptionPlanChange.vue';
import SubscriptionPlanEnterprise from '@/components/Settings/Dronists/Subscription/SubscriptionPlanEnterprise.vue';
import SubscriptionPlanReactivate from '@/components/Settings/Dronists/Subscription/SubscriptionPlanReactivate.vue';

export default {
  name: 'Subscription',
  props: {
    subscriptionState: {
      type: String,
      required: false,
    },
  },
  components: {
    AffiliateInformation,
    InsurancePartner,
    SubscriptionPlan,
    SubscriptionPlanCancel,
    SubscriptionPlanChange,
    SubscriptionPlanEnterprise,
    SubscriptionPlanReactivate,
  },
  data() {
    return {
      dialogSubscription: false,
      dialogSubscriptionChange: false,
      targetFormuleData: null,
      dialogSubscriptionCancel: false,
      dialogSubscriptionReactivate: false,
      dialogSubscriptionEnterprise: false,
    };
  },
  computed: {
    subscriptionFree() {
      return {
        code: 'FREE',
        display: true,
        name: this.$gettext('Free'),
        price: '0€',
        priceInfo: '',
        mainDescription: this.$gettext('Unlimited flight in partner airports areas only.'),
        description: this.$gettext(`<ul><li>Flight creation and regulatory analysis</li><li>
          Approval requests in partner airports</li><li>Importation of drones from AlphaTango</li>
          <li>Flight constraints sheet</li><li>Activity monitoring</li></ul>`),
        displayButton: (
          !this.userFormationOrEntreprise
          && this.subscriptionPlanCode !== 'FREE'
          && !this.isTrialPeriod
          && !this.cancelDate
        ),
        buttonText: this.$gettext('Switch to free'),
        buttonValue: 'FREE',
        targetFormuleData: null,
      };
    },
    subscriptionSolo() {
      return {
        code: 'SOLO',
        display: !this.userNewPlans || this.isTrialPeriod,
        name: this.$gettext('Solo'),
        price: '29€',
        priceInfo: this.$gettext('HT/month'),
        mainDescription: this.$gettext('Unlimited flights in all French territories.'),
        description: this.$gettextInterpolate(
          this.$gettext(`Free functionalities with more:<ul><li>Off sight, in agglomeration and
            military notifications</li><li>Creation of flight exclusion zone</li><li>OACI map</li>
            <li>Handle Geoportail basemap</li><li>Approval requests to U-SpaceKeeper partner zones
            (<a target="_blank" class="%{ articleClass }"
            href="https://clearance.aero/demande-daccord-aux-aeroports-u-space-keeper-via-clearance/"
            >see complete list</a>)</li></ul><br><span class="font-italic">Limited to only one
            pilot. Online payment. Without obligation.</span>`),
          { articleClass: this.userNewPlans ? 'white--text' : '' },
        ),
        displayButton: !this.userFormationOrEntreprise,
        buttonText: this.$gettext('Switch to solo'),
        buttonValue: 'SOLO',
      };
    },
    subscriptionSoloMonthly() {
      let buttonText;
      if (this.cancelDate) {
        buttonText = this.$gettext('Reactivate my subscription');
      } else {
        buttonText = this.$gettext('Switch to solo yearly');
      }
      return {
        code: 'PER_SEAT_M',
        display: !this.isTrialPeriod && this.subscriptionPlanCode === 'PER_SEAT_M',
        name: this.$gettext('Solo monthly'),
        price: '29€',
        priceInfo: this.$gettext('HT/month'),
        mainDescription: this.subscriptionSolo.mainDescription,
        description: this.subscriptionSolo.description,
        displayButton: !this.userFormationOrEntreprise,
        buttonText,
        buttonValue: 'PER_SEAT_M',
        targetFormuleData: {
          icon: this.icons.PER_SEAT_M,
          name: this.names.PER_SEAT_M,
          description: this.descriptions.PER_SEAT_M,
          price: this.prices.PER_SEAT_M,
          formule: 'monthly',
          code: 'PER_SEAT_M',
        },
      };
    },
    subscriptionSoloYearly() {
      let buttonText;
      if (this.cancelDate) {
        buttonText = this.$gettext('Reactivate my subscription');
      } else {
        buttonText = this.$gettext('Switch to solo monthly');
      }
      return {
        code: 'PER_SEAT_Y',
        display: !this.isTrialPeriod && this.subscriptionPlanCode === 'PER_SEAT_Y',
        name: this.$gettext('Solo yearly'),
        price: '290€',
        priceInfo: this.$gettext('HT/year'),
        mainDescription: this.subscriptionSolo.mainDescription,
        description: this.subscriptionSolo.description,
        displayButton: !this.userFormationOrEntreprise,
        buttonText,
        buttonValue: 'PER_SEAT_Y',
        targetFormuleData: {
          icon: this.icons.PER_SEAT_Y,
          name: this.names.PER_SEAT_Y,
          description: this.descriptions.PER_SEAT_Y,
          price: this.prices.PER_SEAT_Y,
          formule: 'yearly',
          code: 'PER_SEAT_Y',
        },
      };
    },
    subscriptionEnterprise() {
      return {
        code: 'ENTERPRISE',
        display: true,
        name: this.$gettext('Enterprise'),
        price: '-',
        priceInfo: '',
        mainDescription: this.$gettext('Advanced functionalities<br>and team management.'),
        description: this.$gettext(`Solo functionalities with more:<ul><li>Tags, KML, data export
          </li><li>API</li><li>Administration right</li><li>Nominative access</li><li>Customize
          account</li><li>Import personalized data</li></ul><br><span class="font-italic">Unlimited
          pilots. Payment by transfer possible.</span>`),
        displayButton: (
          this.userFormationOrEntreprise ? false : this.subscriptionPlanCode !== 'ENTERPRISE'
        ),
        buttonText: this.$gettext('Contact us'),
        buttonValue: 'ENTERPRISE',
        targetFormuleData: null,
      };
    },
    subscriptionFormation() {
      return {
        code: 'FORMATION',
        display: this.subscriptionPlanCode === 'FORMATION',
        name: this.$gettext('Formation'),
        price: '-',
        priceInfo: '',
        mainDescription: this.$gettext('Special partnership access.'),
        description: '',
        displayButton: false,
        buttonText: '',
        buttonValue: '',
        targetFormuleData: null,
      };
    },
    subscriptionFirstFlightMonthly() {
      return {
        code: 'FF_M',
        display: this.subscriptionPlanCode === 'FF_M',
        name: this.$gettext('First flight monthly'),
        price: '19€',
        priceInfo: this.$gettext('HT/month'),
        mainDescription: this.$gettext(`Unlimited flight in partner airports areas et 2 flights by
          month outside of partner airports areas.`),
        description: this.$gettext(`Free functionalities with more:<ul><li>Prefecture notification
          </li><li>Off sight notification</li><li>Military notification</li><li>Creation of flight
          exclusion zone</li><li>OACI map</li></ul>`),
        displayButton: false,
        buttonText: '',
        buttonValue: '',
        targetFormuleData: null,
      };
    },
    subscriptionFirstFlightYearly() {
      return {
        code: 'FF_Y',
        display: this.subscriptionPlanCode === 'FF_Y',
        name: this.$gettext('First flight yearly'),
        price: '190€',
        priceInfo: this.$gettext('HT/year'),
        mainDescription: this.$gettext(`Unlimited flight in partner airports areas et 25 flights by
          month outside of partner airports areas.`),
        description: this.subscriptionFirstFlightMonthly.description,
        displayButton: false,
        buttonText: '',
        buttonValue: '',
        targetFormuleData: null,
      };
    },
    subscriptionIndependantMonthly() {
      return {
        code: 'INDEP_M',
        display: this.subscriptionPlanCode === 'INDEP_M',
        name: this.$gettext('Independant monthly'),
        price: '49€',
        priceInfo: this.$gettext('HT/month'),
        mainDescription: this.$gettext('Unlimited flights in all French territories.'),
        description: this.$gettext(`Free functionalities with more:<ul><li>Prefecture notification
          </li><li>Off sight notification</li><li>Military notification</li><li>Creation of flight
          exclusion zone</li><li>OACI map</li></ul>`),
        displayButton: false,
        buttonText: '',
        buttonValue: '',
        targetFormuleData: null,
      };
    },
    subscriptionIndependantYearly() {
      return {
        code: 'INDEP_Y',
        display: this.subscriptionPlanCode === 'INDEP_Y',
        name: this.$gettext('Independant yearly'),
        price: '490€',
        priceInfo: this.$gettext('HT/year'),
        mainDescription: this.subscriptionIndependantMonthly.mainDescription,
        description: this.subscriptionIndependantMonthly.description,
        displayButton: false,
        buttonText: '',
        buttonValue: '',
        targetFormuleData: null,
      };
    },
    subscriptionPlans() {
      return [
        this.subscriptionFree,
        this.subscriptionSolo,
        this.subscriptionSoloMonthly,
        this.subscriptionSoloYearly,
        this.subscriptionEnterprise,
        this.subscriptionFormation,
        this.subscriptionFirstFlightMonthly,
        this.subscriptionFirstFlightYearly,
        this.subscriptionIndependantMonthly,
        this.subscriptionIndependantYearly,
      ];
    },
    userNotSubscribed() {
      return this.$store.getters['authentication/userNotSubscribed'];
    },
    userEnterprise() {
      return this.$store.getters['authentication/isUserEnterprise'];
    },
    userFormation() {
      return this.subscriptionPlan.code === 'FORMATION';
    },
    userOldPlans() {
      return ['FF_M', 'FF_Y', 'INDEP_M', 'INDEP_Y'].includes(this.subscriptionPlanCode);
    },
    userNewPlans() {
      return ['PER_SEAT_M', 'PER_SEAT_Y'].includes(this.subscriptionPlanCode);
    },
    userFormationOrEntreprise() {
      return this.userFormation || this.userEnterprise;
    },
    userRefreshing() {
      return this.$store.state.authentication.userRefreshing;
    },
    loadingPilots() {
      return this.$store.state.pilots.loadingPilots;
    },
    userData() {
      return this.$store.state.authentication.user;
    },
    subscription() {
      return this.userData.subscription;
    },
    isTrialPeriod() {
      return this.subscription.trial;
    },
    beforeTrialPeriod() {
      return this.subscription.trial && this.subscription.trial_end_date === null;
    },
    subscriptionPlan() {
      return this.subscription.subscription_plan;
    },
    subscriptionPlanCode() {
      return this.subscriptionPlan.code;
    },
    moreDetailsLink() {
      return this.$gettext(`Check our <a href="https://clearance.aero/preparation-missions-drone/tarifs/" target="_blank">our
        article on subscription offers</a> for more details about prices and formulas.`);
    },
    currentFormuleData() {
      return this.formatFormuleData(this.subscriptionPlan.code);
    },
    cancelDate() {
      if (this.subscription.cancel_at) {
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };
        return new Date(this.subscription.cancel_at).toLocaleString(
          this.$store.getters['application/currentLanguage'], options,
        );
      }
      return null;
    },
    trialEndDate() {
      if (this.beforeTrialPeriod) {
        return this.$gettext('30 days after the creation of your first mission');
      }
      return this.subscription.renew_date;
    },
    icons() {
      return {
        FREE: 'mdi-battery-20',
        FF_M: 'mdi-battery-60',
        FF_Y: 'mdi-battery-60',
        INDEP_M: 'mdi-battery',
        INDEP_Y: 'mdi-battery',
        FORMATION: 'mdi-battery',
        PER_SEAT_M: 'mdi-battery',
        PER_SEAT_Y: 'mdi-battery-charging',
        ENTERPRISE: 'mdi-battery-charging',
      };
    },
    names() {
      return {
        FF_M: this.$gettext('First flights'),
        FF_Y: this.$gettext('First flights'),
        INDEP_M: this.$gettext('Independant'),
        INDEP_Y: this.$gettext('Independant'),
        PER_SEAT_M: this.$gettext('Solo formule'),
        PER_SEAT_Y: this.$gettext('Solo formule'),
      };
    },
    formuleLabels() {
      return {
        monthly: this.$gettext('monthly'),
        yearly: this.$gettext('yearly'),
      };
    },
    descriptions() {
      return {
        FREE: this.$gettext('Unlimited free requests for authorizations near partner airports'),
        FF_M: this.$gettext(`<span class="bold primary--text">2 missions</span> each month outside
          of the partner airports areas.`),
        FF_Y: this.$gettext(`<span class="bold primary--text">25 missions</span> each year outside
          of the partner airports areas.`),
        PER_SEAT_M: this.$gettext('Unlimited missions and AlphaTango notifications everywhere.'),
        PER_SEAT_Y: this.$gettext('Unlimited missions and AlphaTango notifications everywhere.'),
        INDEP_M: this.$gettext(`<span class="bold primary--text">Unlimited missions</span>
          everywhere.`),
        INDEP_Y: this.$gettext(`<span class="bold primary--text">Unlimited missions</span>
          everywhere.`),
        FORMATION: this.$gettext(`<span class="bold primary--text">Unlimited missions</span>
          everywhere.`),
        ENTERPRISE: this.$gettext(`<span class="bold primary--text">Unlimited missions</span>
          everywhere.`),
      };
    },
    prices() {
      return {
        FF_M: this.$gettext('19 € HT / month'),
        FF_Y: this.$gettext('190 € HT / year'),
        INDEP_M: this.$gettext('49 € HT / month'),
        INDEP_Y: this.$gettext('490 € HT / year'),
        PER_SEAT_M: this.$gettext('29 € HT / month / pilot'),
        PER_SEAT_Y: this.$gettext('290 € HT / year / pilot'),
      };
    },
    pricesForPlanCards() {
      return {
        PER_SEAT_M: {
          monthlyPrice: this.$gettext('29 € HT / month'),
          priceDetail: this.$gettext('only one pilot'),
        },
        PER_SEAT_Y: {
          monthlyPrice: this.$gettext('24,17 € HT / month'),
          priceDetail: this.$gettext('only one pilot'),
          annualPrice: this.$gettext('290 € HT / year'),
        },
      };
    },
    formules() {
      return [
        {
          data: this.formatFormuleData('PER_SEAT_Y'),
          label: this.formuleLabels.yearly,
          value: 'yearly',
        },
        {
          data: this.formatFormuleData('PER_SEAT_M'),
          label: this.formuleLabels.monthly,
          value: 'monthly',
        },
      ];
    },
    currentFormuleIndex() {
      if (this.isTrialPeriod) {
        return this.subscriptionPlans.findIndex((plan) => plan.code === 'SOLO');
      }
      return this.subscriptionPlans.findIndex((plan) => plan.code === this.subscriptionPlanCode);
    },
  },
  created() {
    // Get pilots to compute prorate on subscription change
    this.$store.dispatch(GET_SIMPLIFIED_PILOTS_NS);
  },
  methods: {
    getButtonOulined(active) {
      return !active;
    },
    getCardColor(active) {
      return active ? 'primary' : '';
    },
    getTextColor(active) {
      return active ? 'white--text' : 'black--text';
    },
    getTextColorAdditionalInfo(active) {
      return active ? 'white--text' : 'grey--text text--darken-3';
    },
    getColorButton(active) {
      return active ? 'white' : 'black';
    },
    getColorTextButton(active) {
      return active ? 'primary--text' : 'black--text';
    },
    formatFormuleData(code) {
      return {
        code,
        name: this.names[code],
        icon: this.icons[code],
        description: this.descriptions[code],
        price: this.prices[code],
        priceForPlanCard: this.pricesForPlanCards[code],
      };
    },
    changeSubscriptionPlan(code) {
      this.targetFormuleData = null;
      switch (code) {
        case 'FREE':
          this.dialogSubscriptionCancel = true;
          break;
        case 'SOLO':
          this.dialogSubscription = true;
          break;
        case 'PER_SEAT_M':
          this.targetFormuleData = this.subscriptionSoloYearly.targetFormuleData;
          if (this.cancelDate) {
            this.dialogSubscriptionReactivate = true;
          } else {
            this.dialogSubscriptionChange = true;
          }
          break;
        case 'PER_SEAT_Y':
          this.targetFormuleData = this.subscriptionSoloMonthly.targetFormuleData;
          if (this.cancelDate) {
            this.dialogSubscriptionReactivate = true;
          } else {
            this.dialogSubscriptionChange = true;
          }
          break;
        case 'ENTERPRISE':
          this.dialogSubscriptionEnterprise = true;
          break;
        default:
          break;
      }
    },
    openSubscriptionChange(targetFormule) {
      this.dialogSubscription = false;
      this.targetFormuleData = targetFormule;
      this.dialogSubscriptionChange = true;
    },
    closeSubscriptionChange() {
      this.dialogSubscriptionChange = false;
      this.targetFormuleData = null;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.link {
  cursor: pointer;
  text-decoration: underline;
}
.link:hover {
  text-decoration-color: $color-primary;
  span {
    color: $color-primary;
  }
}
.subscription-plan__header {
  text-align: center;
  padding-bottom: 16px;
  height: 24px;
}
.fix-height {
  height: 14px;
}
.v-slide-group {
  ::v-deep .v-slide-group__next,
  ::v-deep .v-slide-group__prev {
    min-width: 24px;
  }
}

</style>
