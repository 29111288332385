<template>
  <LayoutListingMap
    :display-button-add="canCreateInstance"
    @clear-selected="setFlightSelected('')"
  >
    <!-- -------- Listing -------- -->
    <template
      slot="listing"
      slot-scope="{display, toggleBiggerDisplay, toggleSmallerDisplay, toggleMap, status}"
    >
      <v-tabs
        v-model="active"
        color="white"
        hide-slider
        background-color="secondary"
        dark
        grow
        show-arrows
        center-active
        v-if="isStatusRead(status) && !selectedFlight"
        class="default--tabs"
      >
        <!-- ----------- Flights tab ------- -->
        <v-tab
          data-tour-step="1"
          href="#tab-flights"
          ripple
          class="subtitle-2"
          @click="openMissionsTab()"
        >
          <span>
            <translate>Missions</translate>
            ({{ flights.itemsLength }})
          </span>
        </v-tab>
        <v-tab-item
          value="tab-flights"
          class="tab-item"
        >
          <FlightFilters
            :display="display"
            @show-filters="toggleBiggerDisplay"
            @hide-filters="toggleSmallerDisplay"
          />
          <FlightListing
            :display="display"
            :status="status"
            @weather-flight="flight => openDialogWeatherFlight(flight)"
            @center-on-flight="flight => { centerOnFlight(flight); toggleMap(); }"
            @edit-flight="flight => prepareFlightToBeEdited(flight, false)"
            @duplicate-flight="flight => prepareFlightToBeDuplicated(flight, false)"
            @delete-flights="flights => openDialogDeleteFlights(flights)"
            @close-flight="flights => openDialogCloseFlights(flights)"
            @export-flights-area="flights => openDialogFlighsAreaExport(flights)"
            @generate-constraint-sheets="flights => openDialogFlightsConstraintSheet(flights)"
            v-show="isStatusRead(status)"
          />
        </v-tab-item>
        <!-- ----------- Pilots tab ------- -->
        <v-tab
          data-tour-step="2"
          href="#tab-pilots"
          ripple
          class="subtitle-2"
          @click="openPilotsTab()"
        >
          <span>
            <translate>Pilots</translate>
            ({{ activePilotsCount }})
          </span>
        </v-tab>
        <v-tab-item
          value="tab-pilots"
          class="tab-item"
        >
          <PilotListing
            v-if="isStatusRead(status)"
            @edit-pilot="pilot => editPilot(pilot)"
            @delete-pilot="pilot => openDialogDeletePilot(pilot)"
          />
          <!-- ----- Delete pilot ------ -->
          <v-dialog
            v-model="dialogDeletePilot"
            max-width="580"
          >
            <PilotDelete
              v-if="pilotToDelete"
              :pilotToDelete="pilotToDelete"
              @close="closeDeletePilotDialog()"
            />
          </v-dialog>
        </v-tab-item>
        <!-- ----------- Drones tab ------- -->
        <v-tab
          data-tour-step="3"
          color="white"
          href="#tab-drones"
          ripple
          class="subtitle-2"
          @click="openDronesTab()"
        >
          <span>
            <translate>Drones</translate>
            ({{ activeDronesCount }})
          </span>
        </v-tab>
        <v-tab-item
          value="tab-drones"
          class="tab-item"
        >
          <DroneListing
            v-if="isStatusRead(status)"
            @edit-drone="drone => editDrone(drone)"
            @delete-drone="drone => openDialogDeleteDrone(drone)"
          />
          <!-- -------- Delete drone -------- -->
          <v-dialog
            v-model="dialogDeleteDrone"
            max-width="580"
          >
            <DroneDelete
              v-if="droneToDelete"
              :droneToDelete="droneToDelete"
              @close="closeDeleteDroneDialog()"
            />
          </v-dialog>
        </v-tab-item>
      </v-tabs>
      <!-- Selected Flight Approval -->
      <template v-if="
        isStatusRead(status)
        && applicationActiveTab.flight && selectedFlight || selectedFlightApproval
      ">
        <FlightApproval v-if="selectedFlightApproval" />
        <!-- ------- Flight Details -------- -->
        <Flight
          v-if="selectedFlight && !selectedFlightApproval && !isGeneralistPilotOrManager"
          :flight="selectedFlight"
          @weather-flight="flight => openDialogWeatherFlight(flight)"
          @center-on-flight="flight => { centerOnFlight(flight); toggleMap(); }"
          @edit-flight="flight => prepareFlightToBeEdited(flight, true)"
          @duplicate-flight="flight => prepareFlightToBeDuplicated(flight, true)"
          @delete-flights="flight => openDialogDeleteFlights([flight])"
          @close-flight="flight => openDialogCloseFlights([flight])"
          @create-exclusion-zone="flight => openFlightExclusionZoneForm(flight)"
        />
        <!-- ----- Flight Simplified Details ----- -->
        <FlightSimplified
          v-if="selectedFlight && !selectedFlightApproval && isGeneralistPilotOrManager"
          :flight="selectedFlight"
          @weather-flight="flight => openDialogWeatherFlight(flight)"
          @center-on-flight="flight => { centerOnFlight(flight); toggleMap(); }"
          @edit-flight="flight => prepareFlightToBeEdited(flight, true)"
          @duplicate-flight="flight => prepareFlightToBeDuplicated(flight, true)"
          @delete-flights="flight => openDialogDeleteFlights([flight])"
          @close-flight="flight => openDialogCloseFlights([flight])"
          @create-exclusion-zone="flight => openFlightExclusionZoneForm(flight)"
        />
      </template>
      <!-- ------- Flight Form -------- -->
      <FlightForm
        ref="flightForm"
        v-if="!isStatusRead(status) && applicationActiveTab.flight && !openFlightFormSimplified"
        :flightToEdit="flightToEdit"
        :originalFlight="originalFlight"
        @reset-flight-to-edit="resetFlightToEdit()"
        @update-id-selected="(id) => setFlightSelected(id, false)"
        @show-request-approval-alert="showRequestApprovalAlert=true"
        @show-update-alphatango-alert="(settings) => showUpdateAlphaTangoAlert(settings)"
        @show-update-external-approvals-alert="
          (approvals) => showUpdateExternalApprovalsAlert(approvals)
        "
      />
      <FlightFormSimplified
        ref="flightFormSimplied"
        v-if="!isStatusRead(status) && applicationActiveTab.flight && openFlightFormSimplified"
        :flightToEdit="flightToEdit"
        :clientStructureName="clientStructureName"
        @reset-flight-to-edit="resetFlightToEdit()"
        @update-id-selected="(id) => setFlightSelected(id, false)"
        @show-update-alphatango-alert="(settings) => showUpdateAlphaTangoAlert(settings)"
        @destroy="resetFlightToEdit()"
      />
      <!-- ------- Flight Exclusion Zone Form -------- -->
      <FlightExclusionZoneForm
        v-if="!isStatusRead(status) && applicationActiveTab.flight_exclusion_zone"
        :flight="exclusionZoneFlight"
      />
      <!-- ------- Pilot Form -------- -->
      <PilotForm
        v-if="!isStatusRead(status) && applicationActiveTab.pilot"
        :pilotToEdit="pilotToEdit"
        @reset-pilot-to-edit="resetPilotToEdit()"
        @warn-managers-subscription-update="warnManagersSubscriptionUpdate()"
        @close-dialog-enterprise="setStatus('read'); resetPilotToEdit();"
      />
      <!-- ------- Drone Form -------- -->
      <DroneForm
        v-if="!isStatusRead(status) && applicationActiveTab.drone"
        :droneToEdit="droneToEdit"
        @reset-drone-to-edit="resetDroneToEdit()"
      />
      <!-- ---- Flight Dialogs ---- -->
      <!-- ----- Archive flights ------ -->
      <v-dialog
        v-model="dialogFlightsClose"
        max-width="680"
        :retain-focus="false"
        :fullscreen="isMobileBreakpoint"
      >
        <FlightCloseForm
          v-if="flightsToClose.length"
          :flights="flightsToClose"
          @close="closeFlightsCloseDialog()"
        />
      </v-dialog>
      <!-- ----- Delete flights ------ -->
      <v-dialog
        persistent
        v-model="dialogFlightDeletion"
        max-width="400"
        :retain-focus="false"
      >
        <FlightDeletion
          v-if="flightsToDelete.length"
          :flightsToDelete="flightsToDelete"
          @close="closeDeletionFlightsDialog()"
          @archive="flight => closeFlightFromDeletion(flight)"
        />
      </v-dialog>
      <!-- ----- Export flights area ------ -->
      <v-dialog
        persistent
        v-model="dialogFlightsExportArea"
        max-width="500"
        :retain-focus="false"
      >
        <FlightAreaExport
          v-if="flightsAreaExport.length"
          :flights="flightsAreaExport"
          @close="closeFlightsAreaExportDialog()"
        />
      </v-dialog>
      <!-- ----- Generate flights constraint sheet ------ -->
      <v-dialog
        persistent
        v-model="dialogFlightsConstraintSheet"
        max-width="700"
        :retain-focus="false"
      >
        <FlightConstraintSheetForm
          v-if="flightsConstraintSheet.length"
          :flights="flightsConstraintSheet"
          @close="closeFlightsConstraintSheetDialog()"
        />
      </v-dialog>
      <!-- --------- Request Approval Alert ---------- -->
      <v-dialog
        v-model="showRequestApprovalAlert"
        max-width="760"
        :fullscreen="isMobileBreakpoint"
      >
        <RequestApprovalAlert @close="showRequestApprovalAlert=false" />
      </v-dialog>
      <!-- --------- AlphaTango notifications should be manually updated ---------- -->
      <v-dialog
        v-model="updateAlphaTangoAlert.visibility"
        width="640"
        persistent
        :retain-focus="false"
        :fullscreen="isMobileBreakpoint"
      >
        <v-card class="default--dialog__card">
          <v-card-title v-translate>
            AlphaTango Notifications
          </v-card-title>
          <v-card-text>
            <p v-html="outdatedNotifPopupText"></p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              @click="updateAlphaTangoAlert.visibility=false"
            >
              <translate>Close</translate>
            </v-btn>
            <v-btn disabled>
              <translate>OK</translate>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- --------- External approvals should be updated ---------- -->
      <v-dialog
        v-model="updateExternalApprovalsAlert.visibility"
        width="640"
        persistent
        :fullscreen="isMobileBreakpoint"
      >
        <v-card class="default--dialog__card">
          <v-card-title>
            <translate>Approval Requests</translate>
          </v-card-title>
          <v-card-text>
            {{ updateExternalApprovalsAlert.message }}
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              @click="updateExternalApprovalsAlert.visibility=false"
            >
              <translate>Close</translate>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Alert at attempt to edit an ended flight with a submitted approval -->
      <v-dialog
        v-model="editEndedApprovalFlightDialog"
        max-width="520"
        persistent
      >
        <v-card class="default--dialog__card">
          <v-card-title>
            <translate>Approval request closed</translate>
          </v-card-title>
          <v-card-text>
            <p class="body-1">
              {{ texts.endedApprovalFlightEditMessage }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              class="px-2"
              @click="editEndedApprovalFlightDialog=false;flightToEdit=null"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- --------- Alert at attempt to edit an entrusted flight ---------- -->
      <v-dialog
        v-model="editEntrustedFlightDialog"
        max-width="520"
        persistent
      >
        <v-card class="default--dialog__card">
          <v-card-title>
            <translate>Entrusted procedures</translate>
          </v-card-title>
          <v-card-text>
            <p class="body-1">
              {{ texts.updateEntrust }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="info"
              class="px-2"
              @click="editEntrustedFlightDialog=false;editFlight()"
            >
              <translate class="body-2 text-lowercase">
                Proceed to edit
              </translate>
            </v-btn>
            <v-btn
              color="primary"
              class="px-2"
              @click="editEntrustedFlightDialog=false;flightToEdit=null"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Alert user on android mobile browser -->
      <v-dialog
        v-model="dialogUseMobileAppDialog"
        persistent
        max-width="520"
      >
        <v-card class="default--dialog__card">
          <v-card-title>
            <translate>Mobile application</translate>
          </v-card-title>
          <v-card-text>
            <span v-html="texts.dialogMobileApp" />
          </v-card-text>
          <v-card-actions>
            <v-checkbox
              v-model="stopDisplayUseMobileAppDialog"
              :label="texts.stopDisplayDialog"
              color="primary"
              class="px-3 py-0 ma-0"
              hide-details
            />
            <v-btn
              text
              color="info"
              class="px-3"
              @click.native="closeDialogUseMobileAppDialog()"
            >
              <translate>Close</translate>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Announcement dialog -->
      <v-dialog
        v-if="announcement"
        v-model="dialogAnnouncement"
        persistent
        max-width="840px"
        :retain-focus="false"
        :fullscreen="isMobileBreakpoint"
      >
        <Announcement @close-announcements="closeAnnouncementDialog()" />
      </v-dialog>
      <!-- Weather cards -->
      <Weather ref="weatherFlight" />
      <!-- ----- Add pilot need Enterprise account ------ -->
      <v-dialog
        max-width="580"
        persistent
        v-model="openDialogNotEnterprise"
      >
        <SubscriptionPlanEnterprise @close="setStatus('read')" />
      </v-dialog>
      <!-- The helpful tour guide that will make all things clear. -->
      <v-tour
        name="dronistTour"
        :steps="dronistTourSteps"
        :options="tourOptions"
      />
    </template>
    <template
      slot="map"
      slot-scope="{display, splitActive, setStatusCreate}"
    >
      <!-- -------- Popup form to create mobile network layer on map -------- -->
      <ImportMobileNetworkLayer />
      <!-- -------- Popup form to import geoportail layers on map -------- -->
      <ImportGeoportailMapLayers />
      <!-- -------- Popup form to create layer on map -------- -->
      <ImportMapLayerData @validate-form="$refs.form.validate()" />
      <!-- -------- Popup form to edit layer on map -------- -->
      <EditLayer />
      <!-- -------- Popup form to edit custom data layer on map -------- -->
      <EditMapLayerData />
      <!-- -------- Popup form to delete layer on map -------- -->
      <DeleteMapLayerData />
      <div class="map--container">
        <!-- ------- MAP -------- -->
        <MapboxComponent
          :splitActive="splitActive"
          :display="display"
          @set-status-create-flight="(area) => setStatusCreateFlight(area, setStatusCreate)"
          @set-status-create-flight-generalist="
            (name, area) => setStatusCreateFlightSimplified(name, area, setStatusCreate)
          "
        >
          <template
            v-if="isMobileBreakpoint"
            slot="action-button"
            slot-scope="{className}"
          >
            <FlightFormActions
              v-if="showFlightFormMapActions()"
              :isEditing="getFlightForm().isEditing"
              :valid="getFlightForm().valid"
              :loading="getFlightForm().loading"
              @create-flight="getFlightForm().createFlight()"
              @update-flight="getFlightForm().updateFlight()"
              :class="className"
            />
          </template>
        </MapboxComponent>
        <!-- Livestream Player Popup  -->
        <LivestreamPlayerPopup
          v-if="showLivestreamsPopUp"
          :livestream="livestreamPlaying"
        />
        <v-snackbar
          v-model="loadingSnackbar"
          class="loading-snackbar d-flex justify-center"
          color="white"
          timeout="-1"
          absolute
          top
        >
          <v-progress-linear indeterminate />
        </v-snackbar>
      </div>
    </template>
  </LayoutListingMap>
</template>

<script>
import LayoutListingMap from '@/layouts/LayoutListingMap.vue';

import { EXTERNAL_OPERATORS } from '@/settings';

import APIService from '@/services/api';

import {
  SET_STATUS_NS,
  APPLICATION_STATUS,
  SET_ACTIVE_TAB_NS,
  APPLICATION_TAB,
  FORCE_SETTINGS_MENU_OPEN_NS,
} from '@/store/application';
import {
  ADD_OR_UPDATE_EXPLOITANT_CONNECTED_NS,
  REFRESH_USER_DATA_NS,
  GET_USER_SUB_EXPLOITANTS_NS,
  STOP_DISPLAY_USE_MOBILE_APP_ALERT_NS,
} from '@/store/authentication';
import { GET_DRONES_NS } from '@/store/drones';
import {
  FETCH_TAG_NAMES_NS,
  GET_EXPLOITATION_DETAILS_NS,
  GET_LATEST_ANNOUNCEMENT_NS,
  WARN_MANAGERS_UPGRADE_TO_ENTERPRISE_NS,
} from '@/store/exploitants';
import {
  GET_FLIGHT_DETAILS_NS,
  SET_FLIGHT_SELECTED_NS,
} from '@/store/flights';
import {
  ZOOM_TO_AREA_NS,
  SET_MAP_AREA_NS,
  SET_MAP_STATUS_NS,
  MAP_STATUS,
} from '@/store/map';
import { GET_NOTIFICATIONS_NS } from '@/store/notifications';
import { GET_PILOTS_NS } from '@/store/pilots';
import { GET_STRUCTURES_GEOMETRIES_NS } from '@/store/structures';

import Announcement from '@/components/Header/Announcement.vue';
import DroneDelete from '@/components/Drones/DroneDelete.vue';
import DroneListing from '@/components/Drones/DroneListing.vue';
import DroneForm from '@/components/Drones/DroneForm.vue';
import FlightConstraintSheetForm from '@/components/Flights/Documents/FlightConstraintSheetForm.vue';
import Flight from '@/components/Flights/Flight.vue';
import FlightSimplified from '@/components/Flights/FlightSimplified.vue';
import FlightAreaExport from '@/components/Flights/FlightAreaExport.vue';
import FlightCloseForm from '@/components/Flights/FlightCloseForm.vue';
import FlightDeletion from '@/components/Flights/FlightDeletion.vue';
import FlightExclusionZoneForm from '@/components/Flights/FlightExclusionZoneForm.vue';
import FlightFilters from '@/components/Flights/FlightFilters.vue';
import FlightApproval from '@/components/Flights/FlightApproval.vue';
import FlightForm from '@/components/Flights/FlightForm.vue';
import FlightFormActions from '@/components/Flights/FlightFormActions.vue';
import FlightFormSimplified from '@/components/Flights/FlightFormSimplified.vue';
import FlightListing from '@/components/Flights/FlightListing.vue';
import RequestApprovalAlert from '@/components/Flights/RequestApprovalAlert.vue';
import LivestreamPlayerPopup from '@/components/Livestreams/LivestreamPlayerPopup.vue';
import MapboxComponent from '@/components/Map/Map.vue';
import EditLayer from '@/components/Map/Popup/EditLayer.vue';
import EditMapLayerData from '@/components/Map/Popup/EditMapLayerData.vue';
import DeleteMapLayerData from '@/components/Map/Popup/DeleteMapLayerData.vue';
import ImportMapLayerData from '@/components/Map/Popup/ImportMapLayerData.vue';
import ImportGeoportailMapLayers from '@/components/Map/Popup/ImportGeoportailMapLayers.vue';
import ImportMobileNetworkLayer from '@/components/Map/Popup/ImportMobileNetworkLayer.vue';
import PilotDelete from '@/components/Pilots/PilotDelete.vue';
import PilotForm from '@/components/Pilots/PilotForm.vue';
import PilotListing from '@/components/Pilots/PilotListing.vue';
import SubscriptionPlanEnterprise from '@/components/Settings/Dronists/Subscription/SubscriptionPlanEnterprise.vue';
import Weather from '@/components/Weather/Weather.vue';

// UI Tour options to display a tour step in the middle of the page (~hack)
/* eslint no-param-reassign: ["error", { "props": false }] */
const noElementAttachedTourOptions = {
  placement: 'none',
  modifiers: {
    computeStyle: {
      enabled: true,
      fn(data) {
        data.styles = {
          ...data.styles,
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        };
        return data;
      },
    },
  },
};

export default {
  name: 'MapDronists',
  components: {
    Announcement,
    LayoutListingMap,
    DroneDelete,
    DroneListing,
    DroneForm,
    FlightConstraintSheetForm,
    Flight,
    FlightSimplified,
    FlightAreaExport,
    FlightCloseForm,
    FlightDeletion,
    FlightExclusionZoneForm,
    FlightFilters,
    FlightApproval,
    FlightForm,
    FlightFormActions,
    FlightFormSimplified,
    FlightListing,
    RequestApprovalAlert,
    LivestreamPlayerPopup,
    MapboxComponent,
    EditLayer,
    EditMapLayerData,
    DeleteMapLayerData,
    ImportMobileNetworkLayer,
    ImportMapLayerData,
    ImportGeoportailMapLayers,
    PilotDelete,
    PilotForm,
    PilotListing,
    SubscriptionPlanEnterprise,
    Weather,
  },
  data() {
    return {
      active: '',
      flightToEdit: null,
      originalFlight: null,
      flightsToClose: [],
      dialogFlightsClose: false,
      flightsToDelete: [],
      dialogFlightDeletion: false,
      flightsAreaExport: [],
      dialogFlightsExportArea: false,
      flightsConstraintSheet: [],
      dialogFlightsConstraintSheet: false,
      exclusionZoneFlight: null,
      editEntrustedFlightDialog: false,
      editEndedApprovalFlightDialog: false,
      showRequestApprovalAlert: false,
      updateAlphaTangoAlert: {
        visibility: false,
        settings: {},
      },
      updateExternalApprovalsAlert: {
        visibility: false,
        message: '',
      },
      pilotToEdit: null,
      pilotToDelete: null,
      dialogDeletePilot: false,
      droneToEdit: null,
      droneToDelete: null,
      dialogDeleteDrone: false,
      tourOptions: {
        debug: false,
        highlight: true,
        labels: {
          buttonSkip: this.$gettext('Skip'),
          buttonPrevious: this.$gettext('Previous'),
          buttonNext: this.$gettext('Next'),
          buttonStop: this.$gettext('Finished'),
        },
      },
      dronistTourSteps: [
        // https://github.com/pulsardev/vue-tour
        {
          target: 'body',
          content: this.$gettext(`Welcome to Clearance! Do you have 2 minutes for a short guided
            tour?`),
          params: noElementAttachedTourOptions,
        },
        {
          target: 'body',
          content: this.$gettext(`You find yourself on the platform home page. Your flights list is
            on your the left side, as well as your pilots and drones lists. A map is on the right
            side, where you can visualize and draw your flights.`),
          params: noElementAttachedTourOptions,
        },
        {
          target: '[data-tour="addButton"]',
          content: this.$gettext(`Use this button to create a mission, or another element depending
            on the active tab.`),
          params: { placement: 'top' },
        },
        {
          target: '.list-maps-button',
          content: this.$gettext(`Inspect your missions over different background maps: plan,
            satellite, aeronautics (OACI).`),
        },
        {
          target: '.mapboxgl-ctrl-geocoder--input',
          content: this.$gettext('Look for a specific place here.'),
        },
        {
          before: () => new Promise((resolve) => {
            this.forceSettingsMenuOpen(true);
            resolve();
          }),
          target: '[data-tour="activityTracking"]',
          content: this.$gettext(`Your missions will fill a flight log accessible from the top
            right menu.`),
          params: noElementAttachedTourOptions,
        },
        {
          target: '[data-tour="settingsPageLink"]',
          content: this.$gettext(`The settings page allows you to change your personal
            informations, upload documents to use later, start a subscription etc.`),
          params: noElementAttachedTourOptions,
        },
        {
          target: '[data-tour="importDronesButton"]',
          content: this.$gettext(`You can <a
            href='https://clearance.aero/importer-ses-drones-depuis-alphatango/' target="_blank">
            import your drones from Alpha Tango</a>, notably to allow notifications sending from
            Clearance.`),
          before: () => new Promise((resolve) => {
            this.forceSettingsMenuOpen(false);
            this.openDronesTab();
            resolve();
          }),
        },
      ],
      externalOperators: EXTERNAL_OPERATORS,
      dialogUseMobileAppDialog: false,
      stopDisplayUseMobileAppDialog: true,
      clientStructureName: undefined,
      dialogAnnouncement: false,
    };
  },
  computed: {
    isMobileBreakpoint() {
      return this.$store.getters['application/isMobileBreakpoint'];
    },
    texts() {
      return {
        endedApprovalFlightEditMessage: this.$gettext(`Your mission has already been the subject of
          an approval request within a registered airport, it is no longer possible to edit it. You
          can, however, duplicate this mission.`),
        stopDisplayDialog: this.$gettext('Do not display this message anymore'),
        dialogMobileApp: this.$gettextInterpolate(
          this.$gettext(`A mobile app is available, you can <a href=%{ url }>download it</a> or
            keep using your mobile browser.<br>Some functionalities are currently only available on
            mobile browser.`),
          { url: 'https://play.google.com/store/apps/details?id=aero.app.clearance' },
        ),
        updateEntrust: this.$gettext(`Flight updates can make it harder for us to obtain your
          authorizations. Please consult with us before updating the mission`),
      };
    },
    applicationActiveTab() {
      const applicationActiveTab = this.$store.getters['application/activeTab'];
      return {
        flight: applicationActiveTab === APPLICATION_TAB.MISSION,
        flight_exclusion_zone: applicationActiveTab === APPLICATION_TAB.MISSION_EXCLUSION_ZONE,
        pilot: applicationActiveTab === APPLICATION_TAB.PILOT,
        drone: applicationActiveTab === APPLICATION_TAB.DRONE,
      };
    },
    idSelected() {
      return this.$store.getters['map/flightSelected'];
    },
    mapCenters() {
      return this.$store.getters['flights/mapCenters'];
    },
    mapAreas() {
      return this.$store.getters['flights/mapAreas'];
    },
    drones() {
      return this.$store.state.drones;
    },
    activeDronesCount() {
      return this.$store.getters['drones/activeDrones'].length;
    },
    pilots() {
      return this.$store.state.pilots;
    },
    activePilotsCount() {
      return this.$store.getters['pilots/activePilots'].length;
    },
    flights() {
      return this.$store.state.flights;
    },
    selectedFlight() {
      return this.$store.getters['flights/flightSelected'];
    },
    selectedFlightApproval() {
      return this.$store.state.flights.flightApprovalIdSelected;
    },
    exploitationId() {
      return this.$store.state.authentication.user.exploitation_id;
    },
    isSubExploitantManager() {
      return this.$store.getters['authentication/isUserExploitantManager'];
    },
    isGeneralistPilot() {
      return this.$store.getters['authentication/isGeneralistPilot'];
    },
    isGeneralistPilotOrManager() {
      return this.$store.getters['authentication/isGeneralistPilotOrManager'];
    },
    canCreateInstance() {
      if (this.isGeneralistPilot) {
        return false;
      }
      if (this.active === 'tab-flights' && this.isGeneralistManager) {
        return false;
      }
      if (this.active === 'tab-drones' || this.active === 'tab-pilots') {
        return (
          this.$store.getters['authentication/isUserExploitantAdmin']
          || this.isSubExploitantManager
        );
      }
      if (this.selectedFlight) {
        return false;
      }
      return true;
    },
    status() {
      return this.$store.state.application.status;
    },
    canAddPilot() {
      return this.$store.state.authentication.user.can_add_pilots;
    },
    openDialogNotEnterprise() {
      if (this.status === APPLICATION_STATUS.CREATE && this.active === 'tab-pilots') {
        return !this.canAddPilot;
      }
      return false;
    },
    displayUseMobileAppAlert() {
      return this.$store.state.authentication.user.display_use_mobile_app_alert;
    },
    isUserBrowserAndroidCompatible() {
      const { userAgent } = navigator;
      return userAgent.match(/Mobi/i) && (
        userAgent.match(/Android/i)
        || userAgent.match(/Opera Mini/i)
        || userAgent.match(/IEMobile/i)
        || userAgent.match(/WPDesktop/i)
        || userAgent.match(/Nexus/i)
      );
    },
    displayUseMobileAppDialog() {
      return this.isUserBrowserAndroidCompatible && this.displayUseMobileAppAlert;
    },
    livestreamPlaying() {
      return this.$store.getters['livestreams/livestreamPlaying'];
    },
    openFlightFormSimplified() {
      return this.clientStructureName !== undefined || this.isGeneralistPilotOrManager;
    },
    currentArea() {
      return this.$store.state.map.currentArea;
    },
    announcement() {
      return this.$store.state.exploitants.announcement;
    },
    showLivestreamsPopUp() {
      return (!this.$store.getters['exploitants/hideLivestreamsFeature']
        && !this.isMobileBreakpoint
        && this.livestreamPlaying
      );
    },
    loadingSnackbar() {
      return this.$store.state.application.loadingSnackbar;
    },
    sliceFlightArea() {
      return this.$store.state.flights.sliceFlightArea;
    },
    displayDronistNotificationsList() {
      return this.$store.getters['authentication/displayDronistNotificationsList'];
    },
    outdatedNotifPopupText() {
      const { settings } = this.updateAlphaTangoAlert;
      const args = {};
      let interpolated = '';
      if (settings.cancelledNotifsArmyCount > 0) {
        interpolated = this.$gettext(
          '%{ cancelledNotifsArmyCount } notifications sent to the army have been cancelled.<br>',
        );
        args.cancelledNotifsArmyCount = settings.cancelledNotifsArmyCount;
      }
      if (settings.outdatedNotifsArmyCount > 0) {
        interpolated += this.$gettext(
          '%{ outdatedNotifsArmyCount } notifications sent to the armies have been outdated.',
        );
        args.outdatedNotifsArmyCount = settings.outdatedNotifsArmyCount;
      }
      if (settings.outdatedNotifsPrefectureCount > 0) {
        if (settings.outdatedNotifsPrefectureCount > 1) {
          interpolated += this.$gettext(
            ` %{ outdatedNotifsPrefectureCount } notifications to the prefecture aren't
            up to date.<br>`,
          );
        } else {
          interpolated += this.$gettext(
            ` %{ outdatedNotifsPrefectureCount } notification to the prefecture isn't
            up to date.<br>`,
          );
        }
        args.outdatedNotifsPrefectureCount = settings.outdatedNotifsPrefectureCount;
      }
      if (settings.outdatedNotifsArmyCount > 0 || settings.outdatedNotifsPrefectureCount > 0) {
        interpolated += this.$gettext(
          `<b> They will not be updated automatically</b>. If you want to update them you can
          use the button 'Update your notifications'`,
        );
      }
      return this.$gettextInterpolate(interpolated, args);
    },
  },
  watch: {
    // eslint-disable-next-line
    '$route.name'(newVal) {
      this.changeTab(newVal);
    },
    selectedFlight() {
      if (this.active !== 'tab-flights') {
        this.changeTab('flights');
      }
      if (this.selectedFlight) {
        this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
      }
    },
    announcement(newValue) {
      if (newValue && !newValue.read) {
        this.dialogAnnouncement = true;
      }
    },
    sliceFlightArea(newValue) {
      if (newValue) {
        this.prepareFlightToBeEdited(this.selectedFlight, true);
      }
    },
  },
  async mounted() {
    await this.getExploitationDetails(this.exploitationId);
    this.$store.dispatch(REFRESH_USER_DATA_NS);
    this.changeTab(this.$route.name);
    if (this.$store.getters['exploitants/hasSubExploitants']) {
      this.$store.dispatch(GET_USER_SUB_EXPLOITANTS_NS);
    }
    if (this.$store.getters['exploitants/displayAnnouncements']) {
      this.$store.dispatch(GET_LATEST_ANNOUNCEMENT_NS);
    }
    if (this.$store.getters['authentication/isUserEnterprise']) {
      this.$store.dispatch(FETCH_TAG_NAMES_NS);
    }
    this.$store.dispatch(GET_PILOTS_NS);
    this.$store.dispatch(GET_DRONES_NS);
    this.getStructuresGeometries();
    if (this.idSelected) {
      this.updateFlightSelected();
    }
    if (this.displayUseMobileAppDialog) {
      this.dialogUseMobileAppDialog = true;
    }
    this.logExploitantConnectionIfNeeded();
    if (this.displayDronistNotificationsList) {
      this.$store.dispatch(GET_NOTIFICATIONS_NS);
    }
  },
  destroyed() {
    this.setFlightSelected(null);
  },
  methods: {
    getStructuresGeometries() {
      this.$store.dispatch(GET_STRUCTURES_GEOMETRIES_NS);
    },
    openMissionsTab() {
      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
      this.$store.dispatch(SET_MAP_STATUS_NS, MAP_STATUS.READ);
      this.$store.dispatch(SET_ACTIVE_TAB_NS, APPLICATION_TAB.MISSION);
      if (this.$route.name !== 'flights') {
        this.$router.push('/map/flights');
      }
    },
    openPilotsTab() {
      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
      this.$store.dispatch(SET_ACTIVE_TAB_NS, APPLICATION_TAB.PILOT);
      if (this.$route.name !== 'pilots') {
        this.$router.push('/map/pilots');
      }
    },
    openDronesTab() {
      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
      this.$store.dispatch(SET_ACTIVE_TAB_NS, APPLICATION_TAB.DRONE);
      if (this.$route.name !== 'drones') {
        this.$router.push('/map/drones');
      }
    },
    forceSettingsMenuOpen(isOpen) {
      this.$store.dispatch(FORCE_SETTINGS_MENU_OPEN_NS, isOpen);
    },
    setFlightSelected(id, reload = true) {
      this.$store.dispatch(SET_FLIGHT_SELECTED_NS, { flightId: id, reload });
    },
    getFlightDetails(id) {
      this.$store.dispatch(GET_FLIGHT_DETAILS_NS, { flightId: id });
    },
    updateFlightSelected(id) {
      this.setFlightSelected(id);
      id && this.getFlightDetails(id);
    },
    changeTab(tabName) {
      switch (tabName) {
        case 'flights':
          this.active = `tab-${tabName}`;
          if (this.$route.name !== 'flights') {
            this.$router.push('/map/flights');
          }
          this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
          this.$store.dispatch(SET_ACTIVE_TAB_NS, APPLICATION_TAB.MISSION);
          break;
        case 'drones':
          this.active = `tab-${tabName}`;
          if (this.$route.name !== 'drones') {
            this.$router.push('/map/drones');
          }
          this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
          this.$store.dispatch(SET_ACTIVE_TAB_NS, APPLICATION_TAB.DRONE);
          break;
        case 'pilots':
          this.active = `tab-${tabName}`;
          if (this.$route.name !== 'pilots') {
            this.$router.push('/map/pilots');
          }
          this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
          this.$store.dispatch(SET_ACTIVE_TAB_NS, APPLICATION_TAB.PILOT);
          break;
        default:
      }
    },
    openDialogWeatherFlight(flight) {
      APIService.flightLogWeatherEvent(flight.id);
      this.$refs.weatherFlight.displayWeatherDialog(flight);
    },
    centerOnFlight(flight) {
      const area = this.$turf.multiPolygon(flight.areas.map((a) => a.geometry.coordinates));
      this.$store.dispatch(ZOOM_TO_AREA_NS, area);
    },
    isStatusRead(status) {
      return status === APPLICATION_STATUS.READ;
    },
    checkFlightCanBeEdited() {
      if (this.flightToEdit.has_current_approval) {
        const today = new Date();
        today.setHours(0); today.setMinutes(0); today.setSeconds(0);
        if (new Date(this.flightToEdit.date_end) < today) {
          this.editEndedApprovalFlightDialog = true;
          return;
        }
      }
      if (this.flightToEdit.was_entrusted) {
        this.editEntrustedFlightDialog = true;
      } else {
        this.editFlight();
      }
    },
    prepareFlightToBeEdited(flight, detailsLoaded) {
      if (detailsLoaded) {
        this.flightToEdit = {
          ...flight,
          pilot_ids: flight.pilots.map((pilot) => pilot.id),
          drone_ids: flight.drones.map((drone) => drone.id),
        };
        this.checkFlightCanBeEdited();
      } else {
        this.$store.dispatch(
          GET_FLIGHT_DETAILS_NS,
          { flightId: flight.id, analysis: false, documents: false },
        )
          .then(() => {
            const flightDetails = this.$store.state.flights.flightsCollection.find(
              (f) => f.id === flight.id,
            );
            this.flightToEdit = {
              ...flightDetails,
              pilot_ids: flightDetails.pilots.map((pilot) => pilot.id),
              drone_ids: flightDetails.drones.map((drone) => drone.id),
            };
            this.checkFlightCanBeEdited();
          });
      }
    },
    editFlight() {
      delete this.flightToEdit.pilots;
      delete this.flightToEdit.drones;
      this.centerOnFlight(this.flightToEdit);
      this.setFlightSelected('');
      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.UPDATE);
      this.$store.dispatch(
        SET_MAP_AREA_NS,
        this.flightAreasToFeatureCollection(this.flightToEdit.areas),
      );
      this.$store.dispatch(SET_MAP_STATUS_NS, MAP_STATUS.WRITE);
    },
    prepareFlightToBeDuplicated(flight, detailsLoaded) {
      if (detailsLoaded) {
        this.duplicateFlight(flight);
      } else {
        this.$store.dispatch(GET_FLIGHT_DETAILS_NS, { flightId: flight.id, analysis: false })
          .then(() => {
            const flightDetails = this.$store.state.flights.flightsCollection.find(
              (f) => f.id === flight.id,
            );
            this.duplicateFlight(flightDetails);
          });
      }
    },
    duplicateFlight(flight) {
      this.originalFlight = flight;
      this.$store.dispatch(SET_MAP_AREA_NS, this.flightAreasToFeatureCollection(flight.areas));
      this.centerOnFlight(flight);
      this.setFlightSelected('');
      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.UPDATE);
      this.$store.dispatch(SET_MAP_STATUS_NS, MAP_STATUS.WRITE);
    },
    resetFlightToEdit() {
      this.clientStructureName = undefined;
      this.originalFlight = null;
      this.flightToEdit = null;
      this.$store.dispatch(SET_MAP_AREA_NS, null);
    },
    flightAreasToFeatureCollection(flightAreas) {
      return {
        type: 'FeatureCollection',
        features: flightAreas.map((f) => {
          const feature = {
            type: 'Feature',
            geometry: f.geometry,
            properties: { ...f },
          };
          delete feature.properties.geometry;
          return feature;
        }),
      };
    },
    openDialogDeleteFlights(flights) {
      this.flightsToDelete = flights;
      this.dialogFlightDeletion = true;
    },
    closeDeletionFlightsDialog() {
      this.dialogFlightDeletion = false;
      this.flightsToDelete = [];
    },
    closeFlightFromDeletion(flight) {
      this.closeDeletionFlightsDialog();
      this.openDialogCloseFlights([flight]);
    },
    openDialogFlighsAreaExport(flights) {
      this.flightsAreaExport = flights;
      this.dialogFlightsExportArea = true;
    },
    closeFlightsAreaExportDialog() {
      this.dialogFlightsExportArea = false;
      this.flightsAreaExport = [];
    },
    openDialogFlightsConstraintSheet(flights) {
      this.flightsConstraintSheet = flights;
      this.dialogFlightsConstraintSheet = true;
    },
    closeFlightsConstraintSheetDialog() {
      this.dialogFlightsConstraintSheet = false;
      this.flightsConstraintSheet = [];
    },
    openDialogCloseFlights(flights) {
      this.flightsToClose = flights;
      this.dialogFlightsClose = true;
    },
    closeFlightsCloseDialog() {
      this.dialogFlightsClose = false;
      this.flightsToClose = [];
    },
    openFlightExclusionZoneForm(flight) {
      this.exclusionZoneFlight = flight;
      this.centerOnFlight(flight);
      this.setFlightSelected('');
      this.$store.dispatch(SET_ACTIVE_TAB_NS, APPLICATION_TAB.MISSION_EXCLUSION_ZONE);
      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.CREATE);
      this.$store.dispatch(SET_MAP_STATUS_NS, MAP_STATUS.WRITE);
    },
    editPilot(pilot) {
      this.pilotToEdit = pilot;
      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.UPDATE);
    },
    resetPilotToEdit() {
      this.pilotToEdit = null;
    },
    openDialogDeletePilot(pilot) {
      this.pilotToDelete = pilot;
      this.dialogDeletePilot = true;
    },
    closeDeletePilotDialog() {
      this.dialogDeletePilot = false;
      this.pilotToDelete = null;
    },
    editDrone(drone) {
      this.droneToEdit = drone;
      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.UPDATE);
    },
    resetDroneToEdit() {
      this.droneToEdit = null;
    },
    openDialogDeleteDrone(drone) {
      this.droneToDelete = drone;
      this.dialogDeleteDrone = true;
    },
    closeDeleteDroneDialog() {
      this.dialogDeleteDrone = false;
      this.droneToDelete = null;
    },
    showUpdateAlphaTangoAlert(settings) {
      this.updateAlphaTangoAlert.visibility = true;
      this.updateAlphaTangoAlert.settings = settings;
    },
    showUpdateExternalApprovalsAlert(approvalsToUpdate) {
      let translatedMessage = '';
      const structuresNames = approvalsToUpdate.map(
        (externalStructure) => externalStructure.name,
      );
      if (structuresNames.length) {
        const messages = this.$ngettext(
          // singular
          'The approval request already submitted for %{name} constraint was not updated. You can cancel this request and submit it again with the right updates.',
          // plural
          'The approval requests already submitted were not updated for constraints: %{name}. You can cancel these requests and submit them again with the right updates.',
          structuresNames.length,
        );
        translatedMessage += this.$gettextInterpolate(
          messages,
          { name: structuresNames.join(', ') },
          true,
        );

        const hasActivations = approvalsToUpdate.map(
          (externalStructure) => externalStructure.has_activations,
        ).includes(true);
        if (hasActivations) {
          translatedMessage += ` ${this.$gettext(`Please update also the informations in your
            activations request.`)}`;
        }
        this.updateExternalApprovalsAlert.message = translatedMessage;
        this.updateExternalApprovalsAlert.visibility = true;
      }
    },
    async getExploitationDetails(exploitationId) {
      await this.$store.dispatch(GET_EXPLOITATION_DETAILS_NS, exploitationId);
    },
    setStatus(status) {
      if (status === APPLICATION_STATUS.READ) {
        this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
      }
    },
    setStatusCreateFlight(area, setStatusCreate) {
      this.changeTab('flights');
      this.$store.dispatch(SET_MAP_AREA_NS, area);
      this.$store.dispatch(ZOOM_TO_AREA_NS, area);
      this.$nextTick(() => setStatusCreate());
    },
    setStatusCreateFlightSimplified(name, area, setStatusCreate) {
      this.clientStructureName = name;
      this.setStatusCreateFlight(area, setStatusCreate);
    },
    warnManagersSubscriptionUpdate() {
      this.$store.dispatch(WARN_MANAGERS_UPGRADE_TO_ENTERPRISE_NS)
        .then(() => {
          this.showMessage(
            this.$gettext('Email sent. Our team will contact you quickly.'),
            'success',
          );
          this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.READ);
        });
    },
    closeDialogUseMobileAppDialog() {
      if (this.stopDisplayUseMobileAppDialog) {
        this.$store.dispatch(STOP_DISPLAY_USE_MOBILE_APP_ALERT_NS);
      }
      this.dialogUseMobileAppDialog = false;
    },
    closeAnnouncementDialog() {
      this.dialogAnnouncement = false;
    },
    logExploitantConnectionIfNeeded() {
      if (this.$store.state.authentication.hijackedUserId === null) {
        if (this.$store.state.authentication.exploitantsConnected[this.exploitationId]) {
          const now = Date.now();
          const difference = (
            now - this.$store.state.authentication.exploitantsConnected[this.exploitationId]
          );
          if (difference > 1000 * 3600 * 24) {
            APIService.logExploitantConnection(this.exploitationId);
            this.$store.commit(ADD_OR_UPDATE_EXPLOITANT_CONNECTED_NS, this.exploitationId);
          }
        } else {
          APIService.logExploitantConnection(this.exploitationId);
          this.$store.commit(ADD_OR_UPDATE_EXPLOITANT_CONNECTED_NS, this.exploitationId);
        }
      }
    },
    showFlightFormMapActions() {
      return (this.$refs.flightForm || this.$refs.flightFormSimplied) && this.currentArea;
    },
    getFlightForm() {
      return (
        this.openFlightFormSimplified ? this.$refs.flightFormSimplied : this.$refs.flightForm
      );
    },
  },
};

</script>

<style
  lang="scss"
  scoped
>
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, .4);
}
.map--container {
  position: relative;
  height: 100%;
}
.loading-snackbar {
  opacity: 0.9;
  width: 100%;
}

</style>
